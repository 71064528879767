import React, { useRef, useEffect } from 'react'
import * as Storage from 'helpers/Storage'
import * as Constants from 'helpers/Constants'

import { message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'

/**
 * Parses object to array.
 *
 * @param {object} o - the object to parse
 * @return {array} - the array created from the object.
 */
export function objectToArray(o) {
  let a = []

  if (o) {
    Object.keys(o).forEach((k) => {
      a = [...a, o[k]]
    })
  }

  return a
}

export function isEmpty(val) {
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    JSON.stringify(val) === JSON.stringify({}) ||
    JSON.stringify(val) === JSON.stringify([])
  )
}

export function isNumeric(str) {
  if (typeof str != 'string') return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export function isPositiveNumber(str) {
  return isNumeric(str) && Math.sign(parseFloat(str)) === 1
}

export function getAuthenticationHeaders() {
  const token = Storage.getAccessToken() || getCookie('user-access-token')

  return { headers: { authorization: 'Bearer ' + token } }
}

export function getArrayToObject(array, key = 'id') {
  const obj = {}

  for (let i = 0; i < array.length; i++) {
    obj[array[i][key]] = array[i]
  }

  return obj
}

export function getTemplateOptions(data, type) {
  return Object.values(data).map((item) => ({
    value: item.id,
    label: Constants.TEMPLATES[type].display_name
      .map((col) => item[col])
      .join(', ')
  }))
}

export function findFromCode(types, code) {
  if (types) {
    for (let i = 0; i < types.length; i++) {
      const type = types[i]

      if (type.kod === code) {
        return type
      }

      const child = this.findFromCode(type.avfallstyper, code)

      if (child) {
        return [type, child]
      }
    }
  }

  return null
}

// custom hook for getting previous value
export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/*
 * Since toISOString() is converting to browser timezone
 */
export function dateToString(date) {
  const day = `${date.getDate()}`
  const month = `${date.getMonth() + 1}`
  const year = date.getFullYear()

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export function datetimeToString(date) {
  const day = `${date.getDate()}`
  const month = `${date.getMonth() + 1}`
  const year = date.getFullYear()
  const hours = `${date.getHours()}`
  const minutes = `${date.getMinutes()}`

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
}

export function messageSuccess(text) {
  message.success({
    content: text,
    icon: (
      <FontAwesomeIcon
        icon={faCheckCircle}
        style={{ marginRight: 24, color: '#00FF00' }}
      />
    )
  })
}

export function messageWarning(text) {
  message.success({
    content: text,
    icon: (
      <FontAwesomeIcon
        icon={faExclamationCircle}
        style={{ marginRight: 24, color: '#FF0000' }}
      />
    )
  })
}

export function getCookie(name) {
  var arrayb = document.cookie.split(';')
  const item = arrayb.find((a) => a.trim().startsWith(name + '='))
  if (!item ) {
    return null
  }
  return item.trim().substring(name.length + 1)
}


export function getOmbudOrgId(auth) {
  if (!auth.user.selectedCompany || auth.user.selectedCompany === 1) {
    return null
  }
  const ombudOrg = auth.user.ombudFor.find(ombudOrg => ombudOrg.orgId === auth.user.selectedCompany)
  return ombudOrg.orgId 
}

export function getUserSelectedOrgName(user) {
  if (!user.selectedCompany || user.selectedCompany === 1) {
    return user.organization_name
  }
  const ombudOrg = user.ombudFor.find(ombudOrg => ombudOrg.orgId === user.selectedCompany)
  return ombudOrg.name
}

export function getUserRole(user) {
  if (!user.selectedCompany || user.selectedCompany === 1) {
    return user.carrier ? 'Transportör' : 'Avfallsproducent'
  }
  const ombudOrg = user.ombudFor.find(ombudOrg => ombudOrg.orgId === user.selectedCompany)
  return ombudOrg.role === 'carrier' ? 'Transportör': 'Avfallsproducent'
}

export function isOmbud(user) {
  if (!user.ombudFor || user.ombudFor.length == 0) {
    return false
  } 
  return user.ombudFor.find(ombudOrg => ombudOrg.orgId === user.selectedCompany) !== undefined
}

export function isNotOmbudOrOmbudWithRole(auth, roles) {
  const ombudFor = auth.user.ombudFor && auth.user.ombudFor.length > 0 ? auth.user.ombudFor : null

  if (!ombudFor|| ombudFor.length === 0) {
    return true
  }

  const selectedOmbudFor = ombudFor.find(of => of.orgId === auth.user.selectedCompany)

  if (!selectedOmbudFor) {
    return true
  }

  return roles.includes(selectedOmbudFor.role)
} 
