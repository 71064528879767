import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ReportWasteForm from 'components/containers/reportWaste/Form'

import * as OperationTemplateActions from 'actions/OperationTemplates'
import * as TransporterTemplateActions from 'actions/TransporterTemplates'
import * as ReceiverTemplateActions from 'actions/ReceiverTemplates'
import * as WasteTypeTemplateActions from 'actions/WasteTypeTemplates'
import * as WasteTypeActions from 'actions/WasteTypes'
import * as ReportActions from 'actions/Reports'

import './style.css'
import { getOmbudOrgId } from 'helpers/Functions'

class ReportWaste extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates,
      wasteTypes,
      auth
    } = this.props

    const ombudFor = getOmbudOrgId(auth)

    if (!operationTemplates.fetched) {
      this.props.tryGetAllOperationTemplates(ombudFor)
    }
    if (!transporterTemplates.fetched) {
      this.props.tryGetAllTransporterTemplates(ombudFor)
    }
    if (!receiverTemplates.fetched) {
      this.props.tryGetAllReceiverTemplates(ombudFor)
    }
    if (!wasteTypeTemplates.fetched) {
      this.props.tryGetAllWasteTypeTemplates(ombudFor)
    }

    if (!wasteTypes.fetched) {
      this.props.tryGetAllWasteTypes(ombudFor)
    }
  }

  render() {
    return <ReportWasteForm />
  }
}

function mapStateToProps({
  auth,
  operationTemplates,
  transporterTemplates,
  receiverTemplates,
  wasteTypeTemplates,
  wasteTypes
}) {
  return {
    auth,
    operationTemplates,
    transporterTemplates,
    receiverTemplates,
    wasteTypeTemplates,
    wasteTypes
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...OperationTemplateActions,
      ...TransporterTemplateActions,
      ...ReceiverTemplateActions,
      ...WasteTypeTemplateActions,
      ...WasteTypeActions,
      ...ReportActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportWaste))
