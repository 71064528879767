import * as Types from 'actions/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false,
  errors: null,
  isCreating: false,
  isUpdating: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_ALL_REPORTS_SUCCESS:
    return {
      ...state,
      data: Functions.getArrayToObject(payload)
    }

  case Types.CREATE_REPORT:
    return {
      ...state,
      errors: null,
      isCreating: true
    }

  case Types.CREATE_REPORT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      },
      isCreating: false
    }

  case Types.CREATE_REPORT_FAILED:
    return {
      ...state,
      errors: payload.map((item) => item.Message),
      isCreating: false
    }

  case Types.UPDATE_REPORT:
    return {
      ...state,
      isUpdating: true,
      errors: null
    }

  case Types.UPDATE_REPORT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      },
      isUpdating: false
    }

  case Types.UPDATE_REPORT_FAILED:
    return {
      ...state,
      isUpdating: false,
      errors: payload.map((item) => item.Message)
    }

  case Types.EMPTY_REPORTS:
    return {
      ...INITIAL_STATE
    }
          
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }
  
  case Types.SIGN_REPORT:
    return {
      ...state, 
      isUpdating: true, 
      errors: null
    }

  case Types.SIGN_REPORT_SUCCESS:
    return {
      ...state, 
      isUpdating: false, 
      errors: null
    }    

  case Types.SIGN_REPORT_FAILED:
    return {
      ...state, 
      isUpdating: false, 
      errors: 'Misslyckades signera rapport'
    } 
  case Types.SEND_REPORT:
    return {
      ...state, 
      isUpdating: true, 
      errors: null
    }
  
  case Types.SEND_REPORT_SUCCESS:
    return {
      ...state, 
      isUpdating: false, 
      errors: null
    }    
  
  case Types.SEND_REPORT_FAILED:
    return {
      ...state, 
      isUpdating: false, 
      errors: 'Misslyckades skicka kvitto'
    }     
  default:
    return state
  }
}
