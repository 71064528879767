import * as Types from 'actions/Types'
import * as Storage from 'helpers/Storage'

const INITIAL_STATE = {
  isAuthenticated: false,
  token: null,
  user: null,
  triedSignIn: false,
  error: null,
  testEnvironment: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, authorizing } = action

  switch (type) {
  case Types.SET_SIGNED_IN_SUCCESS:
    Storage.setAccessToken(payload.token)
    Storage.setUserType(payload.user.is_admin)

    return {
      ...state,
      token: payload.token,
      user: payload.user,
      isAuthenticated: true,
      triedSignIn: true,
      error: null,
      testEnvironment: false
    }
  case Types.SET_SIGNED_IN_FAILED:
    return {
      ...state,
      triedSignIn: true,
      error: authorizing ? null : 'Inloggningen misslyckades.'
    }

  case Types.SELECT_COMPANY:
    let notSelectUser = Object.assign({}, state.user) 
    notSelectUser.selectedCompany = undefined
    return {
      ...state, 
      user: notSelectUser
    }

  case Types.SELECTED_COMPANY:
    let updatedUser = Object.assign({}, state.user) 
    updatedUser.selectedCompany = payload.orgId
    return {
      ...state, 
      user: updatedUser
    }

  case Types.UPDATE_SELF_SUCCESS:
    return {
      ...state,
      user: payload
    }

  case Types.CHANGE_ENV:
    return {
      ...state,
      testEnvironment: !state.testEnvironment
    }

  case Types.SIGN_OUT:
    Storage.deleteAccessToken()
    Storage.deleteUserType()

    return INITIAL_STATE

  case Types.SET_FORGOT_PASSWORD_SUCCESS:
    return {
      ...state,
      error: null,
      success: 'Sändning lyckades.',
      message: payload.message
    }

  case Types.SET_FORGOT_PASSWORD_FAILED:
    return {
      ...state,
      success: null,
      error: 'Sändning misslyckades.'
    }
  
  case Types.SET_VERIFY_TOKEN_SUCCESS:
    return {
      ...state,
      verifyError: null,
      verifySuccess: 'Giltig länk.'
    }

  case Types.SET_VERIFY_TOKEN_FAILED:
    return {
      ...state,
      verifySuccess: null,
      verifyError: 'Ogiltig länk.'
    }

  case Types.SET_UPDATE_PASSWORD_SUCCESS:
    return {
      ...state,
      updateError: null,
      updateSuccess: 'Ditt lösenord uppdaterades.'
    }

  case Types.SET_UPDATE_PASSWORD_FAILED:
    return {
      ...state,
      updateSuccess: null,
      updateError: 'Ditt lösenord kunde inte uppdateras.'
    }

  default:
    return state
  }
}
