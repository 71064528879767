import ShortId from 'shortid'
import {
  faEdit,
  faCog,
  faSignOutAlt,
  faHistory,
  faBookmark,
  faUsers,
  faBuilding,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'

export const NAV_ITEMS = [
  {
    label: 'Skapa rapport',
    path: '/report-waste',
    selected: 'report-waste',
    key: ShortId.generate(),
    icon: faEdit
  },
  {
    label: 'Alla rapporter',
    path: '/reporting-history',
    selected: 'reporting-history',
    key: ShortId.generate(),
    icon: faHistory
  },
  {
    label: 'Mallar',
    path: '/templates',
    selected: 'templates',
    key: ShortId.generate(),
    icon: faBookmark
  }
]

export const SETTINGS_ITEMS = (onSignOut) => [
  {
    label: 'Användare',
    path: '/users',
    selected: 'users',
    key: ShortId.generate(),
    icon: faUsers
  },
  {
    label: 'Aktuellt konto',
    path: '/account-settings',
    selected: 'account-settings',
    key: ShortId.generate(),
    icon: faCog
  },
  {
    label: 'Organisation',
    path: '/organizations',
    selected: 'organizations',
    key: ShortId.generate(),
    icon: faBuilding
  },
  {
    label: 'Till manual',
    path: 'http://sverigesallmannytta.se/documents/manual-for-applikation-for-rapportering-av-farligt-avfall',
    selected: 'non-existing-path',
    onClick: () => window.location = 'http://sverigesallmannytta.se/documents/manual-for-applikation-for-rapportering-av-farligt-avfall',
    key: ShortId.generate(),
    icon: faExternalLinkAlt
  },
  {
    label: 'Logga ut',
    path: '/sign-out',
    selected: 'sign-out',
    onClick: () => onSignOut(),
    key: ShortId.generate(),
    icon: faSignOutAlt
  }
]
