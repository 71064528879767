import React from 'react'
import { withRouter } from 'react-router'
import { Row, Col } from 'antd'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import TemplateSection from 'components/containers/templates/Section'

import Badge from 'components/common/Badge'

import * as Constants from 'helpers/Constants'

import './style.css'

const Templates = () => (
  <Row className={'report-waste-form-outer-container'}>
    <Col span={24}>
      <h1>Mallar med bakgrundsuppgifter</h1>
      <Col span={24} className={'display-none-min-900'}>
        <Badge
          header={'Redigera mallar'}
          information={'Det går ej att hantera mallar i mobilläge.'}
          icon={faInfo}
        />
      </Col>
      {Object.keys(Constants.TEMPLATES).map((type, index) => (
        <TemplateSection type={type} key={index} />
      ))}
    </Col>
  </Row>
)

export default withRouter(Templates)
