import React, { Component } from 'react'
import { Row, Col, Form, Input } from 'antd'
import Button from 'components/common/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { SendOutlined } from '@ant-design/icons'
import * as Functions from 'helpers/Functions'
import { getSignature } from 'actions/api/Reports'

import './style.css'

class TransportDocumentModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      signatureReceiverImage: null, 
      signatureTransporterImage: null
    }
  }

  componentDidMount() {
    const {signed_receiver_at, signed_transporter_at} = this.props.report || {}

    if (signed_receiver_at) {
      getSignature(this.props.report.id, 'receiver').then((result) => {
        this.setState({signatureReceiverImage: result.data.image})
      })
    }
    if (signed_transporter_at) {
      getSignature(this.props.report.id, 'transporter').then((result) => {
        this.setState({signatureTransporterImage: result.data.image})
      })
    }    
  }

  render() {
    const doc = {
      ...this.props.report,
      title: `Rapport från ${Functions.dateToString(new Date((this.props.date)))}`,
      signed_by: this.props.report.signed_by,
      signed_at: this.props.report.signed_at,
      received_at: this.props.report.received_at,
      received_by: this.props.report.received_by,
      created_at: this.props.report.created_at,
      date: this.props.date,
      operation_organization_is_swedish: this.props.report.operation.organization_is_swedish
        ? 'Svensk'
        : 'Utländsk',
      receiver_organization_is_swedish: this.props.report.receiver_organization_is_swedish
        ? 'Svensk'
        : 'Utländsk',
      transporter_organization_is_swedish: this.props.report.transporter_organization_is_swedish
        ? 'Svensk'
        : 'Utländsk',
      report_waste_types: this.props.report.waste_types.map((rwt) => {
        const code = rwt.code.toString()
        return {
          reportId: rwt.id,
          mainGroup: code.substring(0, 2),
          subGroup: code.substring(0, 4),
          code,
          weight: `${rwt.weight}kg`
        }
      })
    }  

    console.log(doc)

    return (
      <>
        <Row gutter={16}>
          <Col span={24}>

            <div className="report-section">
              <h1>{doc.title}</h1>
              <div className="divider"></div>
              <div className="section-container">
                <h3>Kontaktuppgifter</h3>
                <div className="divider"></div>
                <div>
                  <div className="static-list-container-item">
                    <p>Organisationsnummer</p>
                    <p>{doc.operation.organization_number}</p>
                  </div>
                  <div className="static-list-container-item">
                    <p>Organisationen är</p>
                    <p>{doc.operation_organization_is_swedish}</p>
                  </div>
                  <div className="static-list-container-item">
                    <p>Verksamhetens namn</p>
                    <p>{doc.operation.operation_name}</p>
                  </div>
                  <div className="static-list-container-item">
                    <p>Verksamhetens CFAR-nummer</p>
                    <p>{doc.operation.cfar_number}</p>
                  </div>
                  <div className="static-list-container-item">
                    <p>Kontaktperson</p>
                    <p>{doc.operation.contact_person}</p>
                  </div>
                  <div className="static-list-container-item">
                    <p>E-postadress</p>
                    <p>{doc.operation.email}</p>
                  </div>
                  <div className="static-list-container-item">
                    <p>Telefon</p>
                    <p>{doc.operation.phone}</p>
                  </div>
                </div>
              </div>
              <div className="section-container">
                <h3>Transportinformation</h3>
                <div className="divider"></div>
                <div className="category-list-container">
                  <div className="category-list-sub-container">
                    <p className="category-list-sub-container-title">Transport</p>
                    <div>
                      <div className="static-list-container-item">
                        <p>Datum för borttransport</p>
                        <p>{doc.date}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Transportören: namn</p>
                        <p>{doc.transporter.organization_name}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Transportören: Organisationen är</p>
                        <p>{doc.transporter_organization_is_swedish}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Transportören: Organisationsnummer</p>
                        <p>{doc.transporter.organization_number}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Mottagare: namn</p>
                        <p>{doc.receiver.organization_name}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Mottagare: Organisationen är</p>
                        <p>{doc.receiver_organization_is_swedish}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Mottagare: Organisations-/personnummer</p>
                        <p>{doc.receiver.organization_number}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Transportsätt</p>
                        <p>{doc.type}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="category-list-container">
                  <div className="category-list-sub-container">
                    <p className="category-list-sub-container-title">
                    Plats för avfallets uppkomst
                    </p>
                    <div>
                      <div className="static-list-container-item">
                        <p>Postadress</p>
                        <p>{doc.address}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Postnummer</p>
                        <p>{doc.zip_code}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Postort</p>
                        <p>{doc.city}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Kommunkod</p>
                        <p>{doc.municipality_code}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="category-list-container">
                  <div className="category-list-sub-container">
                    <p className="category-list-sub-container-title">
                    Plats där avfallets tas emot
                    </p>
                    <div>
                      <div className="static-list-container-item">
                        <p>Adress</p>
                        <p>{doc.receiver.address}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Postnummer</p>
                        <p>{doc.receiver.zip_code}</p>
                      </div>
                      <div className="static-list-container-item">
                        <p>Postort</p>
                        <p>{doc.receiver.city}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-container hazardous-waste">
                <h3>Farligt avfall</h3>
                <div className="divider"></div>
                <div className="category-list-container">
                  { doc.report_waste_types.map((rwt, index)  => (
                    <div key={index} className="category-list-sub-container">
                      <p className="category-list-sub-container-title">Avfall</p>
                      <div>
                        <div className="static-list-container-item">
                          <p>Avfalls ID</p>
                          <p>{rwt.reportId}</p>
                        </div>
                        <div className="static-list-container-item">
                          <p>Avfallstyp, huvudgrupp</p>
                          <p>{rwt.mainGroup}</p>
                        </div>
                        <div className="static-list-container-item">
                          <p>Avfallstyp, undergrupp</p>
                          <p>{rwt.subGroup}</p>
                        </div>
                        <div className="static-list-container-item">
                          <p>Avfallstyp</p>
                          <p>{rwt.code}</p>
                        </div>
                        <div className="static-list-container-item">
                          <p>Avfallsmängd</p>
                          <p>{rwt.weight}</p>
                        </div>
                      </div>
                    </div>
                  ))

                  }
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <h1 className="signature-heading">Signatur avfallslämnare</h1>
                  <p className="report-signature">{doc.reported_by_name}</p>
                  <div className="report-line"></div>
                  <p className="report-signature-label">Underskrift</p>
                  <p className="report-signature-content">{doc.reported_by_name}</p>
                  <div className="report-line"></div>
                  <p className="report-signature-label">Namförtydligande</p>
                  <p className="report-signature-content">{Functions.dateToString(new Date(doc.created_at))}</p>
                  <div className="report-line"></div>
                  <p className="report-signature-label">Datum</p>
                </div>
                <div className="column">
                  <h1 className="signature-heading">Signatur transportföretag</h1>
                  {
                    doc.signed_transporter_at 
                      ?
                      <img className="report-signature-image" src={this.state.signatureTransporterImage} />
                      // <p className="report-signature">signaturbild</p>
                      :
                      <p className="report-signature">{doc.transporter_name}</p>
                  }
                  <div className="report-line"></div>
                  <p className="report-signature-label">Underskrift</p>
                  <p className="report-signature-content">{doc.transporter_name}</p>
                  <div className="report-line"></div>
                  <p className="report-signature-label">Namförtydligande</p>
                  <p className="report-signature-content">{Functions.dateToString(new Date(doc.signed_at || doc.signed_transporter_at))}</p>
                  <div className="report-line"></div>
                  <p className="report-signature-label">Datum</p>
                </div>
                <div className="column">
                  <h1 className="signature-heading">Signatur mottagare</h1>
                  {
                    doc.signed_receiver_at 
                      ?
                      <img className="report-signature-image" src={this.state.signatureReceiverImage} />
                      // <p className="report-signature">signaturbild</p>
                      :
                      <p className="report-signature">{doc.transporter_name}</p>
                  }                  
                  <div className="report-line"></div>
                  <p className="report-signature-label">Underskrift</p>
                  <p className="report-signature-content">{doc.receiver_name}</p>
                  <div className="report-line"></div>
                  <p className="report-signature-label">Namförtydligande</p>
                  {
                    doc.signed_receiver_at 
                      ?
                      <p className="report-signature-content">{Functions.dateToString(new Date(doc.signed_receiver_at))}</p>
                      :
                      <p className="report-signature-content"></p>
                  } 
                  
                  <div className="report-line"></div>
                  <p className="report-signature-label">Datum</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="space-between" align="bottom" className="report-action-buttons">
          <Col>
            <Form onFinish={(values) => this.props.onEmail(this.props.report.id, values.email)}>
              {/* <Form.Item
                label={'Skicka rapport till'}
                name='email'
                rules={[{ required: true, message: 'Ange en email adress' }]}
              >
                <Input
                  type='email'
                  placeholder={'Ange mottagarens email'}
                />
              </Form.Item>   */}
              <Form.Item>     
                <Button 
                  onClick={() => {
                    let email = prompt('Ange en e-post dit rapporten skall skickas i pdf format. (Adressen sparas ej)')
                    this.props.onEmail(this.props.report.id, email)
                  }}
                >
                  <SendOutlined />Skicka med e-post
                </Button>
              </Form.Item>    
            </Form>
          </Col>
          <Col>
            <Button onClick={() => this.props.onDownload(this.props.report.id, this.props.date)}>
              <FontAwesomeIcon icon={faFilePdf} className={'report-download-pdf-icon'} />Ladda ner
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default TransportDocumentModal
