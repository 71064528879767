import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons'
import Logo from 'assets/sveriges_allmannytta_nav.svg'
import { Switch, Tooltip } from 'antd'
import Button from 'components/common/Button' 
import { Button as AntdButton, Upload } from 'antd'

import * as AuthActions from 'actions/Authentication'
import * as Functions from 'helpers/Functions'

// Local files.
import * as Conf from './conf'
import Styles from './styles.module.css'

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      width: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()

    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { width } = this.state
    const { location, history, children, nvHealth, auth } = this.props

    if (width > 900) {
      return (
        <div
          className={`${Styles['navbar-container']} ${
            nvHealth.up ? '' : Styles['reduced-height']
          }`}
        >
          <div
            className={`${Styles['wrapper']} ${
              nvHealth.up ? '' : Styles['reduced-height']
            }`}
          >
            <div>
              <NavbarHeader
                firstName={this.props.auth.user.first_name || ''}
                lastName={this.props.auth.user.last_name || ''}
                organization={this.props.auth.user.organization_name || ''}
                isOmbud={this.props.auth.user.ombudFor && this.props.auth.user.ombudFor.length > 0}
                userType={Functions.getUserRole(this.props.auth.user)}
                isOmbudForSelectedCompany={Functions.isOmbud(this.props.auth.user)}
                selectedCompany={Functions.getUserSelectedOrgName(this.props.auth.user)}
                trySelectCompany={this.props.trySelectCompany}
              />

              <div className={Styles['nav-menu']}>
                {Conf.NAV_ITEMS.map((item) => {
                  return (
                    <NavbarItem
                      key={item.key}
                      item={item}
                      pathname={location.pathname}
                      history={history}
                    />
                  )
                })}
              </div>
            </div>

            <div>
              <div className={Styles['misc-menu']}>
                {Conf.SETTINGS_ITEMS(() => this.props.signOut())
                  .filter(item => {
                    if (Functions.isNotOmbudOrOmbudWithRole(auth, ['admin'])) {
                      return true
                    } else {
                      return item.label !== 'Användare' && item.label !== 'Organisation'
                    }
                  })
                  .map((item) => {
                    return (
                      <NavbarItem
                        key={item.key}
                        item={item}
                        pathname={location.pathname}
                        history={history}
                      />
                    )
                  })}
              </div>
              <div className={Styles['toggle-test-container']}>
                <h6>Aktivera testläge</h6>
                <Tooltip
                  placement={'topRight'}
                  title="I testläge skriver du aldrig till naturvårdsverket."
                >
                  <Switch
                    checked={auth.testEnvironment}
                    onChange={() => this.props.changeEnv()}
                  />
                </Tooltip>
              </div>

              <NavbarFooter />
            </div>
          </div>
          <div
            className={`${Styles['navbar-children-container']} ${
              nvHealth.up ? '' : Styles['reduced-height']
            }`}
          >
            {children}
          </div>
        </div>
      )
    }

    return (
      <div>
        <MobileTopNavbar
          onSignOut={() => this.props.signOut()}
          history={history}
          pathname={location.pathname}
        />
        <div className={Styles['mobile-children-container']}>{children}</div>
        <MobileBottomNavbar history={history} pathname={location.pathname} />
      </div>
    )
  }
}

const NavbarHeader = ({ firstName, lastName, organization, userType, selectedCompany, isOmbudForSelectedCompany, isOmbud, trySelectCompany }) => {
  return (
    <div className={Styles['user-information']}>
      <FontAwesomeIcon icon={faUser} className={Styles['user-icon']} />
      <h6>
        {firstName} {lastName}
      </h6>
      <small>{userType}</small>
      {
        !isOmbudForSelectedCompany &&
          <small>{organization}</small>
      }
      {
        isOmbudForSelectedCompany &&
          <small>{'Ombud för ' + selectedCompany }</small> 
      }
      
      {isOmbud && 
        <AntdButton style={{marginTop: '5px'}} onClick={() => trySelectCompany()}>
          <FontAwesomeIcon style={{marginRight: '10px'}} icon={faBuilding} />Byt företag
        </AntdButton>
      }
    
    </div>
  )
}

const NavbarItem = ({ pathname, history, item }) => {
  const active = pathname.includes(item.selected)
  let textClassName = Styles['menu-text']
  let containerClassName = Styles['menu-option']
  const onClick = item.onClick
    ? () => item.onClick()
    : () => history.push(item.path)

  if (active) {
    textClassName += ` ${Styles['menu-text-active']}`
    containerClassName += ` ${Styles['menu-option-active']}`
  }

  return (
    <div
      className={containerClassName}
      key={item.label}
      onClick={() => onClick()}
    >
      <h6 className={textClassName}>
        <FontAwesomeIcon icon={item.icon} className={Styles['menu-icon']} />
        {item.label}
      </h6>
    </div>
  )
}

const NavbarFooter = () => {
  return (
    <div className={Styles['footer']}>
      <small className={Styles['tiny-text']}>Tjänsten drivs av</small>
      <img src={Logo} className={Styles['logo']} />
    </div>
  )
}

const MobileTopNavbar = ({ onSignOut, history, pathname }) => {
  return (
    <div className={Styles['mobile-top-navbar-container']}>
      {Conf.SETTINGS_ITEMS(() => onSignOut()).map((item) => (
        <MobileTopNavbarItem
          key={item.key}
          item={item}
          history={history}
          pathname={pathname}
        />
      ))}
    </div>
  )
}

const MobileTopNavbarItem = ({ pathname, item, history }) => {
  const active = pathname.includes(item.selected)
  let className = Styles['mobile-top-navbar-item']
  const onClick = item.onClick
    ? () => item.onClick()
    : () => history.push(item.path)

  if (active) {
    className += ` ${Styles['mobile-top-navbar-item-active']}`
  }

  return (
    <div className={className} onClick={() => onClick()}>
      <FontAwesomeIcon icon={item.icon} className={Styles['menu-icon']} />
    </div>
  )
}

const MobileBottomNavbar = ({ history, pathname }) => {
  return (
    <div className={Styles['mobile-bottom-navbar-container']}>
      {Conf.NAV_ITEMS.map((item) => (
        <MobileBottomNavbarItem
          key={item.key}
          item={item}
          history={history}
          pathname={pathname}
        />
      ))}
    </div>
  )
}

const MobileBottomNavbarItem = ({ item, history, pathname }) => {
  const active = pathname.includes(item.selected)
  let className = Styles['mobile-bottom-navbar-item']

  if (active) {
    className += ` ${Styles['mobile-bottom-navbar-item-active']}`
  }

  return (
    <div className={className} onClick={() => history.push(item.path)}>
      <FontAwesomeIcon icon={item.icon} className={Styles['menu-icon']} />
      <small>{item.label}</small>
    </div>
  )
}

function mapStateToProps({ auth, nvHealth }) {
  return { auth, nvHealth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar))
