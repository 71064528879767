import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { CheckCircleOutlined } from '@ant-design/icons'

// Local files.
import Styles from './styles.module.css'

export const HEADERS = (selfId, onDelete, setAlert, resetAlert) => [
  {
    title: 'Namn',
    dataIndex: 'name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Epostadress',
    dataIndex: 'email',
    sorter: (a, b) => (a.email > b.email ? 1 : -1)
  },
  {
    title: 'Telefonnummer',
    dataIndex: 'phone',
    sorter: (a, b) => (a.email > b.email ? 1 : -1)
  },
  {
    title: 'Extern',
    dataIndex: 'external',
    sorter: (a, b) => (a.external > b.external ? 1 : -1),
    render: (showIcon) => (
      showIcon && <CheckCircleOutlined />
    )
  },  
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    render: function (col) {
      return selfId !== col.id ? (
        <div className={Styles['actions-container']}>
          <div
            onClick={() =>
              setAlert({
                header: 'Bekräfta borttagning',
                contentHeader: 'Ta bort användare',
                content:
                  'Är du säker på att du vill ta bort användaren? Om du gör det så kommer kontot att försvinna och personen som använder sig av kontot kan inte längre använda det.',
                faIcon: 'faTrash',
                okText: 'Ta bort',
                cancelText: 'Avbryt',
                onOk: () => {
                  onDelete(col.id)
                  resetAlert()
                }
              })
            }
            className={Styles['actions-icon']}
          >
            <FontAwesomeIcon icon={faTrash} className={Styles['icon']} />
          </div>
        </div>
      ) : null
    }
  }
]

export const PAGE_SIZE = 25
