import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Form } from 'antd'

import Logo from 'assets/sveriges_allmannytta.svg'

import Divider from 'components/common/Divider'
import Button from 'components/common/Button'
import Input from 'components/common/Input'

import * as AuthActions from 'actions/Authentication'

import './style.css'

class UpdatePassword extends Component {

  componentDidMount() {
    this.props.verifyToken(this.props.match.params.id)
  }

  render() {
    const { auth } = this.props
    const admin = window.location.href.split('?').length > 1
    const ref = React.createRef()
    const verifiedLink = auth.verifySuccess ? true : false
    let content
    if (verifiedLink) {
      content = 
      <Form
        layout="vertical"
        onFinish={(values) => {
          this.props.updatePassword(this.props.match.params.id, values.password)
          ref.current.resetFields()
        }}
        ref={ref}
      >
        <div className={'login-header-container'}>
          <h3>Nytt lösenord</h3>
        </div>
        <Divider />        
        <Form.Item
          label="Lösenord"
          name="password"
          rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
        >
          <Input type={'password'} />
        </Form.Item>
        <Form.Item
          label="Upprepa Lösenord"
          name="repeatPassword"
          rules={[
            { required: true, message: 'Obligatoriskt fält.' },
            {
              validator: (_, values) => {
                if (
                  values &&
                  values !== ref.current.getFieldsValue().password
                ) {
                  return Promise.reject('Lösenorden matchar inte.')
                }

                return Promise.resolve()
              }
            }
          ]}
        >
          <Input type={'password'} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'}>
            Uppdatera lösenord
          </Button>
        </Form.Item>
        { auth.updateSuccess ? (
          <Row>
            <Col span={24}>
              <div className={'login-inner-container'}>
                <p className={'success-text'}>{ auth.updateSuccess }</p>
              </div>
            </Col>
            <Col span={24}>
              <div className={'login-inner-container'}>
                <a href={admin ? '/admin' : '/signin'}>Logga in</a>
              </div>
            </Col>
          </Row>
        ) : null }
        { auth.updateError ? (
          <Row>
            <Col span={24}>
              <div className={'login-inner-container'}>
                <p className={'error-text'}>{ auth.updateError }</p>
              </div>
            </Col>
            <Col span={24}>
              <div className={'login-inner-container'}>
                <a href="/forgot-password">Glömt lösenord</a>
              </div>
            </Col>
          </Row>
        ) : null }
      </Form>
    } else {
      content = <Row>
        <Col span={24}>
          <div className={'login-inner-container'}>
            <p className={'error-text'}>{ auth.verifyError }</p>
          </div>
        </Col>
        <Col span={24}>
          <div className={'login-inner-container'}>
            <a href="/forgot-password">Glömt lösenord</a>
          </div>
        </Col>
      </Row>
    }

    return (
      <div className={'login'}>
        <Row>
          <Col span={24}>
            <div className={'login-inner-container'}>
              <img src={Logo} />
              <h1 className={'login-header'}>Farligt avfall</h1>
            </div>
          </Col>
        </Row>
        { content }
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatePassword))
