import * as Types from 'actions/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false,
  isCreating: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.CREATE_USER:
    return {
      ...state,
      isCreating: true
    }

  case Types.CREATE_USER_SUCCESS:
    return {
      ...state,
      data: { ...state.data, [payload.id]: payload },
      isCreating: false
    }

  case Types.DELETE_USER_SUCCESS:
    delete state.data[payload]

    return {
      ...state,
      data: { ...state.data }
    }

  case Types.CREATE_USER_FAILED:
    return {
      ...state,
      isCreating: false
    }

  case Types.GET_ALL_USERS_SUCCESS:
    return {
      ...state,
      data: Functions.getArrayToObject(payload),
      fetched: true
    }

  case Types.EMPTY_USERS:
    return {
      ...INITIAL_STATE
    }
    
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
