import * as Types from 'actions/Types'

const INITIAL_STATE = {
  cancelText: null,
  okText: null,
  onOk: () => {},
  header: null,
  contentHeader: null,
  faIcon: null,
  content: null,
  visible: false,
  size: 'sm',
  errors: null
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.SET_ALERT:
    return {
      cancelText: payload.cancelText,
      okText: payload.okText,
      onCancel: payload.onCancel,
      onOk: payload.onOk,
      header: payload.header,
      contentHeader: payload.contentHeader,
      faIcon: payload.faIcon,
      content: payload.content,
      size: payload.size,
      errors: payload.errors,
      visible: true
    }
  case Types.RESET_ALERT:
    return INITIAL_STATE
  case Types.SIGN_OUT:
    return INITIAL_STATE
  default:
    return state
  }
}
