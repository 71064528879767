import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, Col } from 'antd'

import List from 'components/common/SmallList'
import Section from 'components/common/Section'
import TextField from 'components/common/TextField'
import BigButton from 'components/common/BigButton'
import RadioButton from 'components/common/RadioButton'
import Button from 'components/common/Button'
import Select from 'components/common/Select'

import * as OperationTemplateActions from 'actions/OperationTemplates'
import * as TransporterTemplateActions from 'actions/TransporterTemplates'
import * as ReceiverTemplateActions from 'actions/ReceiverTemplates'
import * as WasteTypeTemplateActions from 'actions/WasteTypeTemplates'
import * as WasteTypeActions from 'actions/WasteTypes'
import * as AlertActions from 'actions/Alert'

import * as Constants from 'helpers/Constants'
import * as Functions from 'helpers/Functions'

import './style.css'

const INIT_STATE = {
  open: false,
  id: null,
  modalData: {},
  errors: {}
}

class Templates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...INIT_STATE
    }
  }

  componentDidMount() {
    const {
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates,
      wasteTypes,
      type, 
      auth
    } = this.props

    const ombudFor = Functions.getOmbudOrgId(auth)

    switch (type) {
    case Constants.OPERATION_TEMPLATES:
      if (!operationTemplates.fetched) {
        this.props.tryGetAllOperationTemplates(ombudFor)
      }
      break
    case Constants.TRANSPORTER_TEMPLATES:
      if (!transporterTemplates.fetched) {
        this.props.tryGetAllTransporterTemplates(ombudFor)
      }
      break
    case Constants.RECEIVER_TEMPLATES:
      if (!receiverTemplates.fetched) {
        this.props.tryGetAllReceiverTemplates(ombudFor)
      }
      break
    case Constants.WASTE_TYPE_TEMPLATES:
      if (!wasteTypeTemplates.fetched) {
        this.props.tryGetAllWasteTypeTemplates(ombudFor)
      }
      if (!wasteTypes.fetched) {
        this.props.tryGetAllWasteTypes(ombudFor)
      }
      break
    default:
      break
    }
  }

  getModalData(id = this.state.id) {
    const { type, wasteTypes } = this.props

    let data = {}
    const row = this.getRow(id)

    if (type === Constants.WASTE_TYPE_TEMPLATES) {
      if (row) {
        const [main, children] = Functions.findFromCode(
          Object.values(wasteTypes.data),
          row.code
        )

        data = {
          mainTable: {
            ...main,
            label: `${main.kod} ${main.beskrivning}`
          },
          middleTable: {
            ...children[0],
            label: `${children[0].kod} ${children[0].beskrivning}`
          },
          endTable: {
            ...children[1],
            label: `${children[1].kod} ${children[1].beskrivning}`
          }
        }
      }
    } else {
      Object.entries(Constants.TEMPLATES[type].attributes).forEach(
        ([attribute, detail]) => {
          if (detail.type === 'String') {
            data[attribute] = row ? row[attribute] : ''
          }
          if (detail.type === 'Radio') {
            data[attribute] = row ? row[attribute] : detail.options[0].value
          }
          if (detail.type === 'Dropdown') {
            data[attribute] = data[attribute] = row ? row[attribute] : undefined
          }
        }
      )
    }

    return data
  }

  getRow(id) {
    const {
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates,
      type
    } = this.props

    switch (type) {
    case Constants.OPERATION_TEMPLATES:
      return operationTemplates.data[id]
    case Constants.TRANSPORTER_TEMPLATES:
      return transporterTemplates.data[id]
    case Constants.RECEIVER_TEMPLATES:
      return receiverTemplates.data[id]
    case Constants.WASTE_TYPE_TEMPLATES:
      return wasteTypeTemplates.data[id]
    default:
      return null
    }
  }

  findErrors(modalData, template) {
    const errors = {}

    if (template !== Constants.WASTE_TYPE_TEMPLATES) {
      Object.entries(Constants.TEMPLATES[template].attributes).forEach(
        ([key, val]) => {
          if (
            modalData[key] === undefined ||
            modalData[key] === null ||
            modalData[key] === ''
          ) {
            errors[
              key
            ] = `${val.label} är ett obligatoriskt fält, se till att fylla i innan du går vidare.`
          } else if (val.validator) {
            if (!val.validator(modalData[key])) {
              errors[key] = val.error
            }
          }
        }
      )
    } else {
      const tables = [
        { value: 'mainTable', label: 'Huvudgrupp' },
        { value: 'middleTable', label: 'Undergrupp' },
        { value: 'endTable', label: 'Avfallstyp' }
      ]

      tables.map((item) => {
        if (!modalData[item.value]) {
          errors[
            item.value
          ] = `${item.label} är ett obligatoriskt fält, se till att fylla i innan du går vidare.`
        }
      })
    }

    return errors
  }

  validate() {
    const { type } = this.props
    const { modalData } = this.state
    const errors = this.findErrors(modalData, type)

    if (Object.keys(errors).length > 0) {
      this.setState({ errors })

      return false
    }

    return true
  }

  create() {
    const { type } = this.props
    const { modalData } = this.state

    if (this.validate()) {
      switch (type) {
      case Constants.OPERATION_TEMPLATES:
        this.props.tryCreateOperationTemplate(modalData)
        break
      case Constants.TRANSPORTER_TEMPLATES:
        this.props.tryCreateTransporterTemplate(modalData)
        break
      case Constants.RECEIVER_TEMPLATES:
        this.props.tryCreateReceiverTemplate(modalData)
        break
      case Constants.WASTE_TYPE_TEMPLATES:
        this.props.tryCreateWasteTypeTemplate({
          code: modalData.endTable.kod
        })
        break
      default:
        break
      }

      this.setState({ open: false, modalData: {} })
    }
  }

  delete(id) {
    const { type } = this.props

    switch (type) {
    case Constants.OPERATION_TEMPLATES:
      this.props.tryDeleteOperationTemplate(id)
      break
    case Constants.TRANSPORTER_TEMPLATES:
      this.props.tryDeleteTransporterTemplate(id)
      break
    case Constants.RECEIVER_TEMPLATES:
      this.props.tryDeleteReceiverTemplate(id)
      break
    case Constants.WASTE_TYPE_TEMPLATES:
      this.props.tryDeleteWasteTypeTemplate(id)
      break
    default:
      break
    }
  }

  update() {
    const { type } = this.props
    const { modalData, id } = this.state

    if (this.validate()) {
      switch (type) {
      case Constants.OPERATION_TEMPLATES:
        this.props.tryUpdateOperationTemplate(id, modalData)
        break
      case Constants.TRANSPORTER_TEMPLATES:
        this.props.tryUpdateTransporterTemplate(id, modalData)
        break
      case Constants.RECEIVER_TEMPLATES:
        this.props.tryUpdateReceiverTemplate(id, modalData)
        break
      case Constants.WASTE_TYPE_TEMPLATES:
        this.props.tryUpdateWasteTypeTemplate(id, {
          code: modalData.endTable.kod
        })
        break
      default:
        break
      }

      this.setState({ open: false, modalData: {}, id: null })
    }
  }

  getItems() {
    const {
      type,
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates
    } = this.props

    switch (type) {
    case Constants.OPERATION_TEMPLATES:
      return Functions.getTemplateOptions(operationTemplates.data, type)
    case Constants.TRANSPORTER_TEMPLATES:
      return Functions.getTemplateOptions(transporterTemplates.data, type)
    case Constants.RECEIVER_TEMPLATES:
      return Functions.getTemplateOptions(receiverTemplates.data, type)
    case Constants.WASTE_TYPE_TEMPLATES:
      return Functions.getTemplateOptions(wasteTypeTemplates.data, type)
    default:
      return []
    }
  }

  parseWasteType(data) {
    return Object.values(data)
      .map((item) => ({
        ...item,
        value: item.kod,
        label: `${item.kod} ${item.beskrivning}`
      }))
      .sort((a, b) => (parseInt(a.value) < parseInt(b.value) ? -1 : 1))
  }

  renderWasteTypeForm() {
    const { modalData, errors } = this.state
    const { wasteTypes } = this.props
    const mainTypes = this.parseWasteType(wasteTypes.data)

    const middle = modalData.mainTable
      ? this.parseWasteType(modalData.mainTable.avfallstyper)
      : []
    const end = modalData.middleTable
      ? this.parseWasteType(modalData.middleTable.avfallstyper)
      : []

    return (
      <div className={'waste-types'}>
        <div className={'waste-type'}>
          <Select
            options={mainTypes}
            placeholder={'Huvudgrupp'}
            value={modalData.mainTable ? modalData.mainTable.label : undefined}
            error={errors.mainTable}
            onChange={(id) => {
              const wt = mainTypes.find((wts) => wts.kod === id)
              this.setState({
                modalData: {
                  ...modalData,
                  mainTable: wt,
                  middleTable: undefined,
                  endTable: undefined
                }
              })
            }}
          />
        </div>
        <div className={'waste-type bottom'}>
          <Select
            options={middle}
            placeholder={'Undergrupp'}
            value={
              modalData.middleTable ? modalData.middleTable.label : undefined
            }
            disabled={!modalData.mainTable}
            error={errors.middleTable}
            onChange={(id) => {
              const wt = middle.find((wts) => wts.kod === id)
              this.setState({
                modalData: {
                  ...modalData,
                  middleTable: wt,
                  endTable: undefined
                }
              })
            }}
          />
        </div>
        <div className={'waste-type-bottom'}>
          <Select
            options={end}
            placeholder={'Avfallstyp'}
            value={modalData.endTable ? modalData.endTable.label : undefined}
            error={errors.endTable}
            disabled={!modalData.middleTable}
            onChange={(id) => {
              const wt = end.find((wts) => wts.kod === id)
              this.setState({
                modalData: {
                  ...modalData,
                  endTable: wt
                }
              })
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    const { type, setAlert, resetAlert, auth } = this.props
    const { open, modalData, id, errors } = this.state
    const items = this.getItems()
    const template = Constants.TEMPLATES[type]
    const editTemplate = Functions.isNotOmbudOrOmbudWithRole(auth, ['admin'])

    return (
      <div className={'sub-container'}>
        <Section
          header={template.plural}
          subHeader={template.sub_header}
          size={'sm'}
          actions={
            <Col>
              {
                editTemplate &&
                <Button
                  className={'display-none-max-900'}
                  onClick={() =>
                    this.setState({
                      open: true,
                      modalData: this.getModalData()
                    })
                  }
                >
                + Lägg till
                </Button>                
              }

            </Col>
          }
        >
          <List
            items={items}
            disabled={!editTemplate}
            onDelete={(item) =>
              setAlert({
                header: 'Bekräfta borttagning',
                contentHeader: 'Ta bort mall',
                content: `Är du säker på att du vill ta bort mallen ${item.label}?`,
                faIcon: 'faTrash',
                okText: 'Ta bort',
                cancelText: 'Avbryt',
                onOk: () => {
                  this.delete(item.value)
                  resetAlert()
                }
              })
            }
            onEdit={(item) =>
              this.setState({
                id: item.value,
                open: true,
                modalData: this.getModalData(item.value)
              })
            }
          />
        </Section>
        <Modal
          visible={open}
          onCancel={() => this.setState(INIT_STATE)}
          footer={null}
        >
          <Section
            header={`Lägg till ${template.singular}`}
            size={'lg'}
            className={'templates-modal-form'}
          >
            <div className={'templates-modal-form'}>
              {type === Constants.WASTE_TYPE_TEMPLATES
                ? this.renderWasteTypeForm()
                : Object.entries(template.attributes).map(
                  ([attribute, detail]) => {
                    if (detail.type === 'String') {
                      return (
                        <TextField
                          label={detail.label}
                          key={detail.key}
                          error={errors[attribute]}
                          value={modalData[attribute] || ''}
                          style={{ width: '100%' }}
                          onChange={(value) =>
                            this.setState({
                              errors: {
                                ...errors,
                                [attribute]: null
                              },
                              modalData: {
                                ...modalData,
                                [attribute]: value
                              }
                            })
                          }
                        />
                      )
                    } else if (detail.type === 'Radio') {
                      return (
                        <div
                          key={detail.key}
                          className={'templates-modal-form-radio'}
                        >
                          <b>{detail.label}</b>
                          <RadioButton
                            checked={modalData[attribute]}
                            items={detail.options}
                            onChange={(option) =>
                              this.setState({
                                modalData: {
                                  ...modalData,
                                  [attribute]: option.value
                                }
                              })
                            }
                          />
                        </div>
                      )
                    } else if (detail.type === 'Dropdown') {
                      return (
                        <Select
                          options={detail.items}
                          placeholder={detail.label}
                          key={detail.key}
                          value={
                            modalData[attribute]
                              ? detail.items.find(
                                (item) =>
                                  item.value === modalData[attribute]
                              ).label
                              : undefined
                          }
                          error={errors[attribute]}
                          onChange={(option) =>
                            this.setState({
                              modalData: {
                                ...modalData,
                                [attribute]: option
                              }
                            })
                          }
                        />
                      )
                    }
                  }
                )}
            </div>
            <BigButton
              onClick={() => (id ? this.update() : this.create())}
              filled
            >
              {id ? 'UPPDATERA MALL' : 'LÄGG TILL MALL'}
            </BigButton>
          </Section>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps({
  auth,
  operationTemplates,
  transporterTemplates,
  receiverTemplates,
  wasteTypeTemplates,
  wasteTypes
}) {
  return {
    auth,
    operationTemplates,
    transporterTemplates,
    receiverTemplates,
    wasteTypeTemplates,
    wasteTypes
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...OperationTemplateActions,
      ...TransporterTemplateActions,
      ...ReceiverTemplateActions,
      ...WasteTypeTemplateActions,
      ...WasteTypeActions,
      ...AlertActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Templates))
