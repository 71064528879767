import * as Types from 'actions/Types'

const INITIAL_STATE = {
  up: null
}

export default (state = INITIAL_STATE, action) => {
  const { type } = action

  switch (type) {
  case Types.SET_NV_HEALTH_SUCCESS:
    return {
      ...state,
      up: true
    }
  case Types.SET_NV_HEALTH_FAILED:
    return {
      ...state,
      up: false
    }
  case Types.SIGN_OUT:
    return INITIAL_STATE
  default:
    return state
  }
}
