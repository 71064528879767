import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/admin-users/'
  : `${process.env.REACT_APP_API}admin-users/`

export function updateSelf(data) {
  return Axios.patch(`${BASE_URL}`, data, Functions.getAuthenticationHeaders())
}
