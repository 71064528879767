import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'antd'

import Logo from 'assets/sveriges_allmannytta.svg'
import Divider from 'components/common/Divider'
import TextField from 'components/common/TextField'
import Button from 'components/common/BigButton'

import * as AuthActions from 'actions/Authentication'

import './style.css'

class AdminLogin extends Component {
  state = {
    email: '',
    password: ''
  }

  render() {
    const { email, password } = this.state
    const { auth } = this.props

    return (
      <div
        className={'login'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            this.props.tryLoginAdmin({ email, password })
          }
        }}
      >
        <Row>
          <Col span={24}>
            <div className={'login-inner-container'}>
              <img src={Logo} />
              <h1 className={'login-header'}>Farligt avfall</h1>
              <p>
                Hantera företagskonton som ska rapportera farligt avfall till avfallsregistret på Naturvårdsverket
              </p>
              <div className={'login-header-container'}>
                <h3>Logga in som administratör</h3>
              </div>
              <Divider />
              <TextField
                label={'E-post'}
                onChange={(v) => this.setState({ email: v })}
                className={'margin-bottom-1rem'}
              />
              <TextField
                label={'Lösenord'}
                type={'password'}
                onChange={(v) => this.setState({ password: v })}
                className={'margin-bottom-1rem'}
              />
              <a className='forgot-password' href="/forgot-password-admin">Glömt lösenord?</a>
              <Button
                onClick={() => this.props.tryLoginAdmin({ email, password })}
              >
                LOGGA IN
              </Button>
              {auth.error ? <p className={'error-text'}>{auth.error}</p> : null}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminLogin))
