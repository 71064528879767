import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faEdit, faInfo } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Modal, Switch } from 'antd'

import Badge from 'components/common/Badge'
import Table from 'components/common/Table'
import ReportWasteForm from 'components/containers/reportWaste/Form'
import TransportDocumentForm from 'components/containers/TransportDocument'

import * as OperationTemplateActions from 'actions/OperationTemplates'
import * as TransporterTemplateActions from 'actions/TransporterTemplates'
import * as ReceiverTemplateActions from 'actions/ReceiverTemplates'
import * as WasteTypeTemplateActions from 'actions/WasteTypeTemplates'
import * as WasteTypeActions from 'actions/WasteTypes'
import * as ReportActions from 'actions/Reports'
import * as AuthActions from 'actions/Authentication'

import { CheckCircleOutlined } from '@ant-design/icons'

import * as Functions from 'helpers/Functions'

import './style.css'

const HEADERS = (onDownloadPdf, onEdit, onShowTransport) => [
  {
    title: 'Skapad',
    dataIndex: 'created_at',
    width: '20%',
    sorter: (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Uppgiftslämnare',
    dataIndex: 'reported_by_name',
    width: '20%',
    sorter: (a, b) => (a.reported_by_name > b.reported_by_name ? 1 : -1)
  },
  {
    title: 'Transport',
    dataIndex: 'date',
    width: '20%',
    sorter: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    // defaultSortOrder: 'false'
  },
  {
    title: 'Avfalls-producent',
    dataIndex: '',
    width: '20%',
    sorter: (a, b) => {
      const a_sort_on = a.operation_name ? a.operation_name : a.report_type.toUpperCase()
      const b_sort_on = b.operation_name ? b.operation_name : b.report_type.toUpperCase()
      return a_sort_on > b_sort_on ? 1 : -1
    },
    render: (col) => {
      if (col.operation_name) {
        return (<span>{col.operation_name}</span>)
      } else {
        return (<span>{col.report_type === 'okänd' ? 'Okänd' : 'Hushåll '}</span>)
      }
    }
  },
  {
    title: 'Plats',
    dataIndex: 'address',
    width: '20%',
    sorter: (a, b) => (a.address > b.address ? 1 : -1)
  },
  {
    title: 'Mottagare',
    dataIndex: 'receiver',
    width: '20%',
    sorter: (a, b) => (a.receiver > b.receiver ? 1 : -1)
  },
  {
    title: 'Skickad till Naturvårdsverket',
    dataIndex: 'sent_to_nvv',
    width: '20%',
    align: 'center',
    // sorter: (a, b) => (a.receiver > b.receiver ? 1 : -1)
    render: (showIcon) => (
      showIcon && <CheckCircleOutlined />
    )
  }, 
  {
    title: 'Signerad av lämnare',
    dataIndex: 'signature_reporter',
    width: '20%',
    align: 'center',
    // sorter: (a, b) => (a.receiver > b.receiver ? 1 : -1)
    render: (showIcon) => (
      showIcon && <CheckCircleOutlined />
    )
  }, 
  {
    title: 'Signerad av transportör',
    dataIndex: 'signature_transporter',
    width: '20%',
    align: 'center',
    // sorter: (a, b) => (a.receiver > b.receiver ? 1 : -1)
    render: (showIcon) => (
      showIcon && <CheckCircleOutlined />
    )
  },  
  {
    title: 'Signerad av mottagare',
    dataIndex: 'signature_receiver',
    width: '20%',
    align: 'center',
    // sorter: (a, b) => (a.receiver > b.receiver ? 1 : -1)
    render: (showIcon) => (
      showIcon && <CheckCircleOutlined />
    )
  },  
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    render: function (col) {
      return (
        <div className={'reporting-history-actions-container'}>
          <div
            onClick={() => onEdit(col.actions)}
            className={'reporting-history-actions-icon'}
          >
            <FontAwesomeIcon icon={faEdit} className={'pdf-icon'} />
          </div>
          <div
            onClick={() => onShowTransport(col.actions, col.actions.date)}
            className={'reporting-history-actions-icon'}
          >
            <FontAwesomeIcon icon={faFilePdf} className={'pdf-icon'} />
          </div>
        </div>
      )
    }
  }
]

const PAGE_SIZE = 25

class ReportingHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editModal: false,
      showTransport: false,
      transportDate: null,
      report: {},
      page: 1
    }
  }

  componentDidMount() {
    const {
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates,
      wasteTypes,
      reports, 
      auth
    } = this.props

    const ombudFor = Functions.getOmbudOrgId(auth)

    if (!reports.fetched) {
      this.props.tryGetAllReports(ombudFor)
    }

    if (!operationTemplates.fetched) {
      this.props.tryGetAllOperationTemplates(ombudFor)
    }
    if (!transporterTemplates.fetched) {
      this.props.tryGetAllTransporterTemplates(ombudFor)
    }
    if (!receiverTemplates.fetched) {
      this.props.tryGetAllReceiverTemplates(ombudFor)
    }
    if (!wasteTypeTemplates.fetched) {
      this.props.tryGetAllWasteTypeTemplates(ombudFor)
    }

    if (!wasteTypes.fetched) {
      this.props.tryGetAllWasteTypes(ombudFor)
    }
  }

  componentDidUpdate(prevProps) {
    const { reports } = this.props

    if (!reports.isUpdating && prevProps.reports.isUpdating) {
      this.setState({ editModal: false })
      this.props.tryGetAllReports(Functions.getOmbudOrgId(this.props.auth))
    }
  }

  formatRows() {
    const { reports } = this.props
    return Object.values(reports.data).map((report) => ({

      created_at: Functions.datetimeToString(new Date(report.created_at)),
      date: Functions.dateToString(new Date(report.date)),
      reported_by_name: report.reported_by_name,
      operation_name: report.operation.operation_name,
      address: `${report.address}${
        report.zip_code ? `, ${report.zip_code}` : ''
      }${report.city ? `, ${report.city}` : ''}`,
      receiver: report.receiver.organization_name,
      sent_to_nvv: true, 
      report_type: report.report_type, 
      signature_reporter: true, 
      signature_transporter: report.signed_at || report.signed_transporter_at, 
      signature_receiver: report.signed_receiver_at !== null , 
      actions: report
    }))
  }

  onDownloadPdf(id, date) {
    this.props.tryDownloadReport(id, date)
  }

  onEdit(report) {
    this.setState({ report, editModal: true })
  }

  onShowTransport(report, date) {
    this.setState({ report, showTransport: true, transportDate: date })
  }

  editModal() {
    const { editModal, report } = this.state
    if (!editModal) {
      return null
    }

    return (
      <Modal
        visible={editModal}
        onCancel={() => this.setState({ editModal: false })}
        footer={null}
        width={'100%'}
        style={{ top: 10 }}
      >
        <ReportWasteForm update report={report} />
      </Modal>
    )
  }

  showTransportDocument() {
    const { showTransport, report, transportDate } = this.state

    if (!showTransport) {
      return null
    }

    return (
      <Modal
        visible={showTransport}
        onCancel={() => this.setState({ showTransport: false })}
        footer={null}
        width={'100%'}
        style={{ top: 10 }}
      >
        <TransportDocumentForm 
          report={report} date={transportDate} 
          onDownload={(id, date) => {
            this.setState({ showTransport: false })
            this.onDownloadPdf(id, date)
          }}
          onEmail={(id, email) => {
            this.setState({ showTransport: false })
          }}
        />
      </Modal>
    )

  }

  render() {
    const { page } = this.state
    const { auth } = this.props

    return (
      <Row className={'report-waste-form-outer-container'} gutter={16}>
        <Col span={24}>
          <h1>Alla rapporter</h1>
          {auth.testEnvironment ? (
            <Badge
              header={'Testläge'}
              information={'Det går ej att se historik i testläge.'}
              icon={faInfo}
            />
          ) : (
            <>
              <Col span={24}>
                <Table
                  headers={HEADERS(
                    (id, date) => this.onDownloadPdf(id, date),
                    (report) => this.onEdit(report), 
                    (report, date) => this.onShowTransport(report, date)
                  )}
                  rows={this.formatRows()}
                  pagination={{ current: page, pageSize: PAGE_SIZE }}
                  onChange={(pagination, filters, sorter) => {
                    this.setState({
                      page: pagination.current
                    })
                  }}
                />
              </Col>

              {this.editModal()}
              {this.showTransportDocument()}
            </>
          )}
        </Col>
      </Row>
    )
  }
}

function mapStateToProps({
  operationTemplates,
  transporterTemplates,
  receiverTemplates,
  wasteTypeTemplates,
  wasteTypes,
  reports,
  auth
}) {
  return {
    operationTemplates,
    transporterTemplates,
    receiverTemplates,
    wasteTypeTemplates,
    wasteTypes,
    reports,
    auth
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...OperationTemplateActions,
      ...TransporterTemplateActions,
      ...ReceiverTemplateActions,
      ...WasteTypeTemplateActions,
      ...WasteTypeActions,
      ...ReportActions,
      ...AuthActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportingHistory))
