import Axios from 'axios'
import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/nv-healthcheck'
  : `${process.env.REACT_APP_API}nv-healthcheck`

export function health() {
  const url = `${BASE_URL}`

  return Axios.get(url, Functions.getAuthenticationHeaders())
}
