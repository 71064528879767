import React, { Component } from 'react'

import { Modal } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './style.css'
import Divider from 'components/common/Divider'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as AlertActions from 'actions/Alert'

import ButtonStyles from 'components/common/Button/styles.module.css'

class Alert extends Component {
  state = {
    faIcon: null
  }

  componentDidUpdate(prevProps) {
    const { alert } = this.props

    if (prevProps.alert.faIcon !== alert.faIcon) {
      const faIcon = require('@fortawesome/free-solid-svg-icons')[alert.faIcon]

      this.setState({ faIcon })
    }
  }

  render() {
    const { faIcon } = this.state
    const { alert, resetAlert } = this.props

    return (
      <Modal
        zIndex={1001}
        cancelText={alert.cancelText}
        okButtonProps={{
          className: `${ButtonStyles.container} ${ButtonStyles['width-fit']}`
        }}
        cancelButtonProps={{
          className: `${ButtonStyles['width-fit']} ${ButtonStyles.container} ${
            ButtonStyles['bordered-container']
          } ${alert.cancelText ? '' : 'hidden'}`
        }}
        okText={alert.okText}
        onCancel={resetAlert}
        onOk={alert.onOk ? () => alert.onOk() : () => resetAlert()}
        title={alert.header}
        visible={alert.visible}
        closable={false}
      >
        <div>
          {alert.contentHeader ? (
            <div className={'content-header-container'}>
              {faIcon ? (
                <FontAwesomeIcon className={'fa-icon'} icon={faIcon} />
              ) : null}
              <h2>{alert.contentHeader}</h2>
            </div>
          ) : null}
          <Divider />
          {alert.content}
          {alert.errors ? (
            <div className={'alert-body'}>
              <ul>
                {alert.errors.map((error, index) => (
                  <li key={`alert-error-list-${index}`}>
                    <p>{error}</p>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps({ alert }) {
  return { alert }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AlertActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert)
