import * as Types from 'actions/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_ALL_ORGANIZATIONS_SUCCESS:
    return {
      ...state,
      data: Functions.getArrayToObject(payload),
      fetched: true
    }

  case Types.CREATE_ORGANIZATION_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }
  
  case Types.UPDATE_ORGANIZATION_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.DELETE_ORGANIZATION_SUCCESS:
    delete state.data[payload]

    return {
      ...state,
      data: {
        ...state.data
      }
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
