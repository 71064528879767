import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Form } from 'antd'

import Button from 'components/common/Button' 
import RadioButton from 'components/common/RadioButton' 
import Section from 'components/common/Section'
import Input from 'components/common/Input'
import Select from 'components/common/Select'

import * as AuthActions from 'actions/Authentication'
import * as WasteTypeTemplateActions from 'actions/WasteTypeTemplates'
import * as TransporterTemplateActions from 'actions/TransporterTemplates'
import * as OperationTemplateActions from 'actions/OperationTemplates'
import * as ReceiverTemplateActions from 'actions/ReceiverTemplates'
import * as ReportsActions from 'actions/Reports'
import * as UserActions from 'actions/Users'

import './style.css'

class SelectCompany extends Component {
  constructor(props) {
    super(props)
    const { auth  } = this.props
    this.state = {
      selectedCompany: undefined  
    }

    this.form = React.createRef()
  }

  componentDidMount() {
  }

  render() {
    const { auth, organizationAPIs } = this.props
    console.log(auth.user)

    const { apiKey } = this.state

    return (
      <div className="select-company">
        <Row justify="center">
          <Col xxs={24} md={8}>
            <h1>Välj företag att representera</h1>

            <h2>Moderbolag:</h2>
            <RadioButton
              checked={this.state.selectedCompany}
              items={[{value: 1, label: auth.user.organization_name + ' (' + (auth.user.isCarrier ? 'Transportör)' : 'Avfallsproducent)')}]}
              onChange={(option) => {
                this.setState({
                  selectedCompany: option.value
                })
              }}
            />
            <h2>Som ombud:</h2>
            <RadioButton
              checked={this.state.selectedCompany}
              items={auth.user.ombudFor.map(c => {
                return {label: c.name + ' (' + (c.role === 'admin' ? 'Avfallsproducent)' : 'Transportör)  '), value: c.orgId}
              })}
              onChange={(option) => {
                this.setState({
                  selectedCompany: option.value
                })
              }}
            />            

            <Button onClick={() => {
              this.props.trySelectedCompany(this.state.selectedCompany) 
              this.props.tryEmptyWasteTypeTemplates()
              this.props.tryEmptyTransporterTemplates()
              this.props.tryEmptyOperationTemplates()              
              this.props.tryEmptyReceiverTemplates()
              this.props.tryEmptyReports()
              this.props.tryEmptyUsers()
            }
            }>Select</Button>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps({ 
  auth, 
  wasteTypeTemplates, 
  transporterTemplateActions,
  operationTemplateActions, 
  receiverTemplateActions, 
  reportActions, 
  userActions
}) {
  return { 
    auth, 
    wasteTypeTemplates, 
    transporterTemplateActions, 
    operationTemplateActions, 
    receiverTemplateActions, 
    reportActions, 
    userActions
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    ...AuthActions, 
    ...WasteTypeTemplateActions, 
    ...TransporterTemplateActions, 
    ...OperationTemplateActions, 
    ...ReceiverTemplateActions, 
    ...ReportsActions, 
    ...UserActions
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelectCompany))
