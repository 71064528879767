import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL =
  !process.env.REACT_APP_API
    ? 'http://localhost:3990/v1/waste-types/'
    : `${process.env.REACT_APP_API}waste-types/`

export function getAll(orgId) {
  return Axios.get(BASE_URL + (orgId ? '?org_id=' + orgId : ''), Functions.getAuthenticationHeaders())
}
