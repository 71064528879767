import * as Types from 'actions/Types'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_ONE_ORGANIZATION_SUCCESS:
    return {
      ...state,
      data: payload,
      fetched: true
    }

  case Types.UPDATE_API_KEY_SUCCESS:
    return {
      ...state,
      data: payload
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
