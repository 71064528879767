import * as Types from 'actions/Types'
import * as NVHealthApi from 'actions/api/NVHealth'

export function tryCheckNVHealth() {
  return (dispatch) => {
    NVHealthApi.health()
      .then(() => {
        dispatch({
          type: Types.SET_NV_HEALTH_SUCCESS
        })
      })
      .catch((e) => {
        if (e.response.status != 401) {
          dispatch({
            type: Types.SET_NV_HEALTH_FAILED
          })
        }
      })
  }
}
