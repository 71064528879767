import React, { Component } from 'react'
import { Modal, Col, Row } from 'antd'

import Section from 'components/common/Section'
import Button from 'components/common/Button'
import Badge from 'components/common/Badge'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import * as Constants from 'helpers/Constants'

import './style.css'

class ControlModal extends Component {
  getValue(data, attribute, item) {
    return data[attribute]
      ? item.options
        ? item.options.find((item) => item.value === data[attribute]).label
        : data[attribute]
      : ''
  }

  getContactDetails() {
    const { data } = this.props

    return Object.entries(
      Constants.TEMPLATES[Constants.OPERATION_TEMPLATES].attributes
    )
      .filter((tup) => !tup[1].hideOnConfirmation)
      .map(([k, v]) => ({
        value: this.getValue(data.operation, k, v),
        label: v.label
      }))
  }

  getTransportDetails(reportType) {
    const details = [
      { label: 'Uppkomst', items: this.getOriginItems(reportType) },
      { label: 'Transport/Transportör', items: this.getTransportItems() },
      {
        label: 'Mottagning/Mottagare',
        items: this.getReceiverItems()
      }
    ]

    return details
  }

  getWasteDetails() {
    const { data } = this.props

    return data.waste_types.map((item, index) => {
      return {
        label: `Avfall [${index}]`,
        items: [
          { label: 'Avfallstyp', value: item.code },
          { label: 'Avfallsmängd', value: item.weight }
        ]
      }
    })
  }

  getOriginItems(reportType) {
    const { data } = this.props
    const details = [
      { label: 'Postadress', value: data.origin.address },
      { label: 'Postnummer', value: data.origin.zip_code },
      { label: 'Postort', value: data.origin.city },
      { label: 'Kommunkod', value: data.origin.municipality_code }
    ]

    if (reportType !== 'transportplanering') {
      return [{ label: 'Typ', value: reportType}, ...details]
    }

    return details
  }

  getReceiverItems() {
    const { data } = this.props

    return Object.entries(
      Constants.TEMPLATES[Constants.RECEIVER_TEMPLATES].attributes
    )
      .filter((tup) => !tup[1].hideOnConfirmation)
      .map(([k, v]) => ({
        value: this.getValue(data.receiver, k, v),
        label: v.label
      }))
  }

  getTransportItems() {
    const { data } = this.props

    return [
      // Date.
      {
        label: 'Datum för borttransport',
        value: data.date
      },
      // Transport type.
      {
        label: 'Transportsätt',
        value: data.type
      },
      // Transporter.
      ...Object.entries(
        Constants.TEMPLATES[Constants.TRANSPORTER_TEMPLATES].attributes
      )
        .filter((tup) => !tup[1].hideOnConfirmation)
        .map(([k, v]) => ({
          value: this.getValue(data.transporter, k, v),
          label: `Transportören: ${v.label}`
        }))
    ]
  }

  render() {
    const { open, onClose, update, onFinish, loading, testEnvironment, data } =
      this.props

    if (open) {
      return (
        <Modal visible={open} onCancel={onClose} footer={null}>
          <Row>
            {testEnvironment ? (
              <Col span={22}>
                <Badge
                  header={'Testläge'}
                  information={
                    'I testläget kan du testa att skapa en rapportering utan att skicka vidare den till Naturvårdsverket. För att faktiskt rapportera in ett farligt avfall, se till att inte vara i testläge.'
                  }
                  icon={faInfo}
                />
              </Col>
            ) : null}

            <Col span={24}>
              <h1>Kontrollera uppgifter</h1>
              {
                data.report_type === 'transportplanering' &&
                <Section header={'Kontaktuppgifter'} size={'md'}>
                  <StaticList items={this.getContactDetails()} />
                </Section>

              }
              <Section header={'Transportinformation'} size={'md'}>
                <CategoryList items={this.getTransportDetails(data.report_type)} />
              </Section>
              <Section header={'Farligt avfall'} size={'md'}>
                <CategoryList items={this.getWasteDetails()} />
              </Section>
              <Row gutter={[16, 16]}>
                <Col xs={24} xl={12}>
                  <Button onClick={onClose} bordered disabled={loading}>
                    Avbryt och redigera
                  </Button>
                </Col>
                <Col xs={24} xl={12}>
                  <Button filled onClick={() => onFinish()} loading={loading}>
                    {update ? 'Uppdatera' : 'Skapa rapport'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      )
    } else {
      return null
    }
  }
}

const StaticList = ({ items }) => (
  <div className={'static-list-container'}>
    {items.map((item, index) => (
      <div className={'static-list-container-item'} key={index}>
        <p>{item.label}</p>
        <p>{item.value}</p>
      </div>
    ))}
  </div>
)

const CategoryList = ({ items }) => {
  return (
    <div className={'category-list-container'}>
      {items.map((category, index) => (
        <div className={'category-list-sub-container'} key={index}>
          <p className={'category-list-sub-container-title'}>
            {category.label}
          </p>
          <StaticList items={category.items} />
        </div>
      ))}
    </div>
  )
}

export default ControlModal
