import { combineReducers } from 'redux'

import auth from 'reducers/Authentication'
import operationTemplates from 'reducers/OperationTemplates'
import reports from 'reducers/Reports'
import transporterTemplates from 'reducers/TransporterTemplates'
import receiverTemplates from 'reducers/ReceiverTemplates'
import wasteTypeTemplates from 'reducers/WasteTypeTemplates'
import wasteTypes from 'reducers/WasteTypes'
import nvHealth from 'reducers/NVHealth'
import users from 'reducers/Users'
import organizations from 'reducers/Organizations'
import alert from 'reducers/Alert'
import organizationAPIs from 'reducers/OrganizationsAPI'

export default combineReducers({
  auth,
  operationTemplates,
  reports,
  transporterTemplates,
  receiverTemplates,
  wasteTypeTemplates,
  wasteTypes,
  nvHealth,
  users,
  organizations,
  alert,
  organizationAPIs
})
