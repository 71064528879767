import * as Types from 'actions/Types'
import * as Api from 'actions/api/WasteTypes'

export function tryGetAllWasteTypes(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_WASTE_TYPES
    })

    Api.getAll(orgId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_WASTE_TYPES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_WASTE_TYPES_FAILED
        })
      })
  }
}
