import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/organizations/'
  : `${process.env.REACT_APP_API}organizations/`

export function getAll() {
  return Axios.get(BASE_URL, Functions.getAuthenticationHeaders())
}

export function create(data) {
  return Axios.post(BASE_URL, data, Functions.getAuthenticationHeaders())
}

export function update(data) {
  return Axios.patch(BASE_URL + data.id, data, Functions.getAuthenticationHeaders())
}


export function remove(id) {
  return Axios.delete(`${BASE_URL}/${id}`, Functions.getAuthenticationHeaders())
}
