import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/operation-templates/'
  : `${process.env.REACT_APP_API}operation-templates/`

export function getAll(orgId) {
  return Axios.get(BASE_URL + (orgId ? '?org_id=' + orgId : ''), Functions.getAuthenticationHeaders())
}

export function create(data) {
  return Axios.post(BASE_URL, data, Functions.getAuthenticationHeaders())
}

export function update(id, data) {
  return Axios.patch(
    `${BASE_URL}/${id}`,
    data,
    Functions.getAuthenticationHeaders()
  )
}

export function remove(id) {
  return Axios.delete(`${BASE_URL}/${id}`, Functions.getAuthenticationHeaders())
}
