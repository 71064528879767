import * as Types from 'actions/Types'
import * as Api from 'actions/api/Organizations'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllOrganizations() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_ORGANIZATIONS
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_ORGANIZATIONS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_ORGANIZATIONS_FAILED
        })
      })
  }
}

export function tryCreateOrganization(data) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_ORGANIZATION
    })

    Api.create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_ORGANIZATION_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Organisationen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ORGANIZATION_FAILED
        })

        messageWarning('Organisationen kunde inte skapas')
      })
  }
}

export function tryUpdateOrganization(data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_ORGANIZATION
    })

    Api.update(data)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ORGANIZATION_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Organisationen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ORGANIZATION_FAILED
        })

        messageWarning('Organisationen kunde inte uppdaterats')
      })
  }
}

export function tryDeleteOrganization(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_ORGANIZATION
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ORGANIZATION_SUCCESS,
          payload: id
        })

        messageSuccess('Organisationen har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ORGANIZATION_FAILED
        })

        messageWarning('Organisationen kunde inte tas bort')
      })
  }
}
