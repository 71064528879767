import React, { Component } from 'react'

import { Table as AntdTable } from 'antd'

// Overrite antd.
import './styles.css'

class Table extends Component {
  render() {
    const { headers, rows, pagination, onChange } = this.props

    return (
      <AntdTable
        showSorterTooltip={false}
        columns={headers}
        dataSource={rows}
        pagination={pagination}
        onChange={(pagination, filter, sort) =>
          onChange(pagination, filter, sort)
        }
      />
    )
  }
}

export default Table
