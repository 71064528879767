import * as Api from 'actions/api/AdminUsers'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryUpdateAdmin(data) {
  return (dispatch) => {
    Api.updateSelf(data)
      .then((response) => {
        messageSuccess('Uppgifterna har uppdaterats')
      })
      .catch(() => {
        messageWarning('Uppgifterna kunde inte uppdateras')
      })
  }
}
