import React from 'react'
import { Input as AntdInput } from 'antd'

// Overrite default antd.
import './styles.css'

const Input = (props) => {
  const {
    onChange,
    className = '',
    placeholder = '',
    value = '',
    disabled,
    size = 'large',
    type = 'text',
    id
  } = props

  const Input = type === 'password' ? AntdInput.Password : AntdInput

  return (
    <Input
      id={id}
      className={className}
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
      type={type}
    />
  )
}

export default Input
