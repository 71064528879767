import { Switch, Route, Redirect } from 'react-router-dom'
import React from 'react'

import Login from './pages/Login/index.js'
import ForgotPassword from './pages/Login/forgot_password.js'
import UpdatePassword from './pages/Login/update_password.js'
import AccountSettings from './pages/AccountSettings/index.js'
import ReportingHistory from './pages/ReportingHistory/index.js'
import ReportWaste from './pages/ReportWaste/index.js'
import Templates from './pages/Templates/index.js'
import Users from './pages/Users/index.js'
import Organizations from './pages/Organizations/func-index.js'
import SelectCompany from './pages/SelectCompany/index.js'

import Admin from './pages/Admin'
import AdminLogin from './pages/AdminLogin/index.js'

import Navbar from 'components/containers/Navbar'
import Alert from 'components/common/Alert'

import * as Storage from 'helpers/Storage'

const SSO = () => {
  return (<p>Loggar in...</p>)
}

const Router = ({ authenticated, triedSignin, isAdmin, nvHealth, notRegisteredUser = false, user }) => {
  if (authenticated && triedSignin) {
    if (isAdmin) {
      return (
        <>
          <Switch>
            <Route exact path={'/admin'} component={Admin} />
            <Route component={() => <Redirect to="/admin" />} />
          </Switch>
          <Alert />
        </>
      )
    }
    if (user && user.ombudFor && user.ombudFor.length > 0 && !user.selectedCompany) {
      return (<>
        <Switch>
          <Route exact path={'/select-company'} component={SelectCompany} />
          <Route component={() => <Redirect to="/select-company" />} />
        </Switch>
        <Alert />
      </>
      )
    }
    return (
      <>
        {nvHealth.up === false ? (
          <div className={'banner'}>
            <h2> Fel upptäckt </h2>
            <h3>Det går ej att nå Naturvårdsverkets tjänster.</h3>
            <h5>
              Detta kan bero på att den angivna API-nyckeln är ogiltig - detta
              åtgärdas genom att lägga till en korrekt API-nyckel under
              Organisation &gt; API-nyckel.
            </h5>
            <h5>
              Om problemet kvarstår - var god återkom senare eller kontakta
              supporten.
            </h5>
          </div>
        ) : null}
        <Navbar>
          <Switch>
            <Route
              exact
              path={'/account-settings'}
              component={AccountSettings}
            />
            <Route exact path={'/users'} component={Users} />
            <Route
              exact
              path={'/reporting-history'}
              component={ReportingHistory}
            />
            <Route exact path={'/report-waste'} component={ReportWaste} />
            <Route exact path={'/templates'} component={Templates} />
            <Route exact path={'/organizations'} component={() => <Organizations auth={{user}}/>} />
            <Route component={() => <Redirect to="/report-waste" />} />
          </Switch>
          <Alert />
        </Navbar>
      </>
    )
  }

  if (triedSignin || !Storage.getAccessToken()) {
    return (
      <Switch>
        <Route exact path={'/admin'} component={AdminLogin} />
        <Route exact path={'/signin'} render={(props) => <Login notRegisteredUser={notRegisteredUser} {...props}/>} />
       
        <Route exact path={'/forgot-password'} component={ForgotPassword} />
        <Route exact path={'/forgot-password-admin'} component={() => <ForgotPassword admin={true}/>} />
        <Route exact path={'/forgot-password/:id'} component={UpdatePassword} />
        <Route exact path={'/sso'} component={SSO} />
        <Route exact path={'*'} component={() => <Redirect to={'/signin'} />} />
      </Switch>
    )
  }

  return null
}

export default Router
