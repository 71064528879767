import React, { useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.css'

const TextField = React.forwardRef(
  (
    {
      onChange,
      label,
      type = 'text',
      style = {},
      value,
      className = '',
      icon,
      id,
      placeholder = '',
      error = null
    },
    ref
  ) => {
    const inputRef = useRef(ref)

    return (
      <div className={`text-field-outer-container ${className || ''}`}>
        <div
          className={`text-field-container ${error ? 'error' : ''}`}
          style={style}
          onClick={() => inputRef.current.focus()}
        >
          {icon ? (
            <div className={'text-field-container-icon'}>
              <FontAwesomeIcon icon={icon} size={'lg'} />
            </div>
          ) : null}
          <div className={'text-field-container-right'}>
            <p>{label}</p>
            <input
              type={type}
              onChange={(e) => onChange(e.target.value)}
              ref={inputRef}
              value={value}
              id={id || undefined}
              placeholder={placeholder}
            />
          </div>
        </div>
        {error ? <p className="error-message">{error}</p> : null}
      </div>
    )
  }
)
TextField.displayName = 'TextField'

export default TextField
