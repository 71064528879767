export const SET_NV_HEALTH_SUCCESS = 'SET_NV_HEALTH_SUCCESS'
export const SET_NV_HEALTH_FAILED = 'SET_NV_HEALTH_FAILED'

// Authentication
export const SET_SIGNED_IN = 'SET_SIGNED_IN'
export const SET_SIGNED_IN_SUCCESS = 'SET_SIGNED_IN_SUCCESS'
export const SET_SIGNED_IN_FAILED = 'SET_SIGNED_IN_FAILED'

export const SELECT_COMPANY = 'SELECT_COMPANY'
export const SELECTED_COMPANY = 'SELECTED_COMPANY'

export const SIGN_OUT = 'SIGN_OUT'

export const CHANGE_ENV = 'CHANGE_ENV'

// Forgot password
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD'
export const SET_FORGOT_PASSWORD_SUCCESS = 'SET_FORGOT_PASSWORD_SUCCESS'
export const SET_FORGOT_PASSWORD_FAILED = 'SET_FORGOT_PASSWORD_FAILED'

// Verify password update token validity
export const SET_VERIFY_TOKEN = 'SET_VERIFY_TOKEN'
export const SET_VERIFY_TOKEN_SUCCESS = 'SET_VERIFY_TOKEN_SUCCESS'
export const SET_VERIFY_TOKEN_FAILED = 'SET_VERIFY_TOKEN_FAILED'

// Update password
export const SET_UPDATE_PASSWORD = 'SET_UPDATE_PASSWORD'
export const SET_UPDATE_PASSWORD_SUCCESS = 'SET_UPDATE_PASSWORD_SUCCESS'
export const SET_UPDATE_PASSWORD_FAILED = 'SET_UPDATE_PASSWORD_FAILED'

// Alert
export const SET_ALERT = 'SET_ALERT'
export const RESET_ALERT = 'RESET_ALERT'

// operation templates
export const GET_ALL_OPERATION_TEMPLATES = 'GET_ALL_OPERATION_TEMPLATES'
export const GET_ALL_OPERATION_TEMPLATES_SUCCESS =
  'GET_ALL_OPERATION_TEMPLATES_SUCCESS'
export const GET_ALL_OPERATION_TEMPLATES_FAILED =
  'GET_ALL_OPERATION_TEMPLATES_FAILED'

export const CREATE_OPERATION_TEMPLATE = 'CREATE_OPERATION_TEMPLATE'
export const CREATE_OPERATION_TEMPLATE_SUCCESS =
  'CREATE_OPERATION_TEMPLATE_SUCCESS'
export const CREATE_OPERATION_TEMPLATE_FAILED =
  'CREATE_OPERATION_TEMPLATE_FAILED'

export const UPDATE_OPERATION_TEMPLATE = 'UPDATE_OPERATION_TEMPLATE'
export const UPDATE_OPERATION_TEMPLATE_SUCCESS =
  'UPDATE_OPERATION_TEMPLATE_SUCCESS'
export const UPDATE_OPERATION_TEMPLATE_FAILED =
  'UPDATE_OPERATION_TEMPLATE_FAILED'

export const DELETE_OPERATION_TEMPLATE = 'DELETE_OPERATION_TEMPLATE'
export const DELETE_OPERATION_TEMPLATE_SUCCESS =
  'DELETE_OPERATION_TEMPLATE_SUCCESS'
export const DELETE_OPERATION_TEMPLATE_FAILED =
  'DELETE_OPERATION_TEMPLATE_FAILED'
export const EMPTY_OPERATION_TEMPLATES =
  'EMPTY_OPERATION_TEMPLATES'

// transporter templates
export const GET_ALL_TRANSPORTER_TEMPLATES = 'GET_ALL_TRANSPORTER_TEMPLATES'
export const GET_ALL_TRANSPORTER_TEMPLATES_SUCCESS =
  'GET_ALL_TRANSPORTER_TEMPLATES_SUCCESS'
export const GET_ALL_TRANSPORTER_TEMPLATES_FAILED =
  'GET_ALL_TRANSPORTER_TEMPLATES_FAILED'

export const CREATE_TRANSPORTER_TEMPLATE = 'CREATE_TRANSPORTER_TEMPLATE'
export const CREATE_TRANSPORTER_TEMPLATE_SUCCESS =
  'CREATE_TRANSPORTER_TEMPLATE_SUCCESS'
export const CREATE_TRANSPORTER_TEMPLATE_FAILED =
  'CREATE_TRANSPORTER_TEMPLATE_FAILED'

export const UPDATE_TRANSPORTER_TEMPLATE = 'UPDATE_TRANSPORTER_TEMPLATE'
export const UPDATE_TRANSPORTER_TEMPLATE_SUCCESS =
  'UPDATE_TRANSPORTER_TEMPLATE_SUCCESS'
export const UPDATE_TRANSPORTER_TEMPLATE_FAILED =
  'UPDATE_TRANSPORTER_TEMPLATE_FAILED'

export const DELETE_TRANSPORTER_TEMPLATE = 'DELETE_TRANSPORTER_TEMPLATE'
export const DELETE_TRANSPORTER_TEMPLATE_SUCCESS =
  'DELETE_TRANSPORTER_TEMPLATE_SUCCESS'
export const DELETE_TRANSPORTER_TEMPLATE_FAILED =
  'DELETE_TRANSPORTER_TEMPLATE_FAILED'
export const EMPTY_TRANSPORTER_TEMPLATES =
  'EMPTY_TRANSPORTER_TEMPLATES'  

// receiver templates
export const GET_ALL_RECEIVER_TEMPLATES = 'GET_ALL_RECEIVER_TEMPLATES'
export const GET_ALL_RECEIVER_TEMPLATES_SUCCESS =
  'GET_ALL_RECEIVER_TEMPLATES_SUCCESS'
export const GET_ALL_RECEIVER_TEMPLATES_FAILED =
  'GET_ALL_RECEIVER_TEMPLATES_FAILED'

export const CREATE_RECEIVER_TEMPLATE = 'CREATE_RECEIVER_TEMPLATE'
export const CREATE_RECEIVER_TEMPLATE_SUCCESS =
  'CREATE_RECEIVER_TEMPLATE_SUCCESS'
export const CREATE_RECEIVER_TEMPLATE_FAILED = 'CREATE_RECEIVER_TEMPLATE_FAILED'

export const UPDATE_RECEIVER_TEMPLATE = 'UPDATE_RECEIVER_TEMPLATE'
export const UPDATE_RECEIVER_TEMPLATE_SUCCESS =
  'UPDATE_RECEIVER_TEMPLATE_SUCCESS'
export const UPDATE_RECEIVER_TEMPLATE_FAILED = 'UPDATE_RECEIVER_TEMPLATE_FAILED'

export const DELETE_RECEIVER_TEMPLATE = 'DELETE_RECEIVER_TEMPLATE'
export const DELETE_RECEIVER_TEMPLATE_SUCCESS =
  'DELETE_RECEIVER_TEMPLATE_SUCCESS'
export const DELETE_RECEIVER_TEMPLATE_FAILED = 'DELETE_RECEIVER_TEMPLATE_FAILED'
export const EMPTY_RECEIVER_TEMPLATES =
  'EMPTY_RECEIVER_TEMPLATES'

// waste type templates
export const GET_ALL_WASTE_TYPE_TEMPLATES = 'GET_ALL_WASTE_TYPE_TEMPLATES'
export const GET_ALL_WASTE_TYPE_TEMPLATES_SUCCESS =
  'GET_ALL_WASTE_TYPE_TEMPLATES_SUCCESS'
export const GET_ALL_WASTE_TYPE_TEMPLATES_FAILED =
  'GET_ALL_WASTE_TYPE_TEMPLATES_FAILED'

export const CREATE_WASTE_TYPE_TEMPLATE = 'CREATE_WASTE_TYPE_TEMPLATE'
export const CREATE_WASTE_TYPE_TEMPLATE_SUCCESS =
  'CREATE_WASTE_TYPE_TEMPLATE_SUCCESS'
export const CREATE_WASTE_TYPE_TEMPLATE_FAILED =
  'CREATE_WASTE_TYPE_TEMPLATE_FAILED'

export const UPDATE_WASTE_TYPE_TEMPLATE = 'UPDATE_WASTE_TYPE_TEMPLATE'
export const UPDATE_WASTE_TYPE_TEMPLATE_SUCCESS =
  'UPDATE_WASTE_TYPE_TEMPLATE_SUCCESS'
export const UPDATE_WASTE_TYPE_TEMPLATE_FAILED =
  'UPDATE_WASTE_TYPE_TEMPLATE_FAILED'

export const DELETE_WASTE_TYPE_TEMPLATE = 'DELETE_WASTE_TYPE_TEMPLATE'
export const DELETE_WASTE_TYPE_TEMPLATE_SUCCESS =
  'DELETE_WASTE_TYPE_TEMPLATE_SUCCESS'
export const DELETE_WASTE_TYPE_TEMPLATE_FAILED =
  'DELETE_WASTE_TYPE_TEMPLATE_FAILED'
export const EMPTY_ALL_WASTE_TYPE_TEMPLATES = 'EMPTY_ALL_WASTE_TYPE_TEMPLATES'

// waste types
export const GET_ALL_WASTE_TYPES = 'GET_ALL_WASTE_TYPES'
export const GET_ALL_WASTE_TYPES_SUCCESS = 'GET_ALL_WASTE_TYPES_SUCCESS'
export const GET_ALL_WASTE_TYPES_FAILED = 'GET_ALL_WASTE_TYPES_FAILED'

// Reports
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS'
export const GET_ALL_REPORTS_SUCCESS = 'GET_ALL_REPORTS_SUCCESS'
export const GET_ALL_REPORTS_FAILED = 'GET_ALL_REPORTS_FAILED'

export const CREATE_REPORT = 'CREATE_REPORT'
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS'
export const CREATE_REPORT_FAILED = 'CREATE_REPORT_FAILED'

export const UPDATE_REPORT = 'UPDATE_REPORT'
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS'
export const UPDATE_REPORT_FAILED = 'UPDATE_REPORT_FAILED'

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT'
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS'
export const DOWNLOAD_REPORT_FAILED = 'DOWNLOAD_REPORT_FAILED'

export const SIGN_REPORT = 'SIGN_REPORT'
export const SIGN_REPORT_SUCCESS = 'SIGN_REPORT_SUCCESS'
export const SIGN_REPORT_FAILED = 'SIGN_REPORT_FAILED'

export const SEND_REPORT = 'SEND_REPORT'
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS'
export const SEND_REPORT_FAILED = 'SEND_REPORT_FAILED'
export const EMPTY_REPORTS = 'EMPTY_REPORTS'

// Users
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const UPDATE_SELF = 'UPDATE_SELF'
export const UPDATE_SELF_SUCCESS = 'UPDATE_SELF_SUCCESS'
export const UPDATE_SELF_FAILED = 'UPDATE_SELF_FAILED'
export const EMPTY_USERS = 'EMPTY_USERS'

// organizations
export const GET_ALL_ORGANIZATIONS = 'GET_ALL_ORGANIZATIONS'
export const GET_ALL_ORGANIZATIONS_SUCCESS = 'GET_ALL_ORGANIZATIONS_SUCCESS'
export const GET_ALL_ORGANIZATIONS_FAILED = 'GET_ALL_ORGANIZATIONS_FAILED'

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAILED = 'CREATE_ORGANIZATION_FAILED'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAILED = 'UPDATE_ORGANIZATION_FAILED'

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_FAILED = 'DELETE_ORGANIZATION_FAILED'

export const GET_ONE_ORGANIZATION = 'GET_ONE_ORGANIZATION'
export const GET_ONE_ORGANIZATION_SUCCESS = 'GET_ONE_ORGANIZATION_SUCCESS'
export const GET_ONE_ORGANIZATION_FAILED = 'GET_ONE_ORGANIZATION_FAILED'

export const UPDATE_API_KEY = 'UPDATE_API_KEY'
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS'
export const UPDATE_API_KEY_FAILED = 'UPDATE_API_KEY_FAILED'
