import * as Types from 'actions/Types'
import * as AuthenticationApi from 'actions/api/Authentication'

export function tryLocalLogin(user) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_SIGNED_IN
    })

    AuthenticationApi.localLogin(user)
      .then((response) => {
        dispatch({
          type: Types.SET_SIGNED_IN_SUCCESS,
          payload: {
            token: response.data.data.access_token,
            user: response.data.data.user
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SET_SIGNED_IN_FAILED
        })
      })
  }
}

export function trySelectCompany() {
  return (dispatch) => {
    dispatch({
      type: Types.SELECT_COMPANY
    })  
  }
}

export function trySelectedCompany(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.SELECTED_COMPANY,
      payload: {
        orgId: orgId
      }
    })  
  }
}

export function tryAuthorize(sso) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_SIGNED_IN
    })
    AuthenticationApi.authorize()
      .then((response) => {
        dispatch({
          type: Types.SET_SIGNED_IN_SUCCESS,
          payload: {
            token: response.data.data.access_token,
            user: response.data.data.user
          }
        })
      })
      .catch(() => {
        if (sso) {
          window.location.href = `${process.env.REACT_APP_API}auth/remote-login`
        }    
        dispatch({
          type: Types.SET_SIGNED_IN_FAILED,
          authorizing: true
        })
      })
  }
}

export function tryLoginAdmin(user) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_SIGNED_IN
    })

    AuthenticationApi.loginAdmin(user)
      .then((response) => {
        dispatch({
          type: Types.SET_SIGNED_IN_SUCCESS,
          payload: {
            token: response.data.data.access_token,
            user: response.data.data.user
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SET_SIGNED_IN_FAILED
        })
      })
  }
}
export function tryAuthorizeAdmin() {
  return (dispatch) => {
    dispatch({
      type: Types.SET_SIGNED_IN
    })

    AuthenticationApi.authorizeAdmin()
      .then((response) => {
        dispatch({
          type: Types.SET_SIGNED_IN_SUCCESS,
          payload: {
            token: response.data.data.access_token,
            user: response.data.data.user
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SET_SIGNED_IN_FAILED,
          authorizing: true
        })
      })
  }
}

export function changeEnv() {
  return (dispatch) => {
    dispatch({
      type: Types.CHANGE_ENV
    })
  }
}

export function signOut() {
  return (dispatch) => {
    dispatch({
      type: Types.SIGN_OUT
    })
  }
}

export function forgotPassword(email, admin) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_FORGOT_PASSWORD
    })

    AuthenticationApi.forgotPassword(email, admin)
      .then((response) => {
        dispatch({
          type: Types.SET_FORGOT_PASSWORD_SUCCESS,
          payload: {
            message: response.data.message
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SET_FORGOT_PASSWORD_FAILED
        })
      })
  }
}

export function verifyToken(token) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_VERIFY_TOKEN
    })

    AuthenticationApi.verifyToken(token)
      .then((response) => {
        dispatch({
          type: Types.SET_VERIFY_TOKEN_SUCCESS,
          payload: {
            message: response.message
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SET_VERIFY_TOKEN_FAILED
        })
      })
  }
}

export function updatePassword(token, password) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_UPDATE_PASSWORD
    })

    AuthenticationApi.updatePassword(token, password)
      .then((response) => {
        dispatch({
          type: Types.SET_UPDATE_PASSWORD_SUCCESS,
          payload: {
            message: response.message
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SET_UPDATE_PASSWORD_FAILED
        })
      })
  }
}