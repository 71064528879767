import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Form } from 'antd'

import Input from 'components/common/Input'
import Section from 'components/common/Section'
import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'

import * as UserActions from 'actions/Users'
import * as Constants from 'helpers/Constants'

import './style.css'

class AccountSettings extends Component {
  constructor(props) {
    super(props)

    this.form = null

    this.state = {
      carrier: false
    }
  }

  componentDidMount() {
    const { auth } = this.props

    this.setState({ carrier: auth.user.carrier || false })

    this.form = React.createRef(null)
  }

  validateFields(fields) {
    const errors = {}

    fields.forEach((field) => {
      if (this.state[field] === '') {
        errors[field] =
          'Obligatoriskt fält, se till att fylla i innan du går vidare.'
      } else if (
        field === 'email' &&
        Constants.REGEX.EMAIL.test(this.state[field])
      ) {
        errors[field] = 'Ej giltig e-postadress.'
      }
    })

    return errors
  }

  savePersonal(values) {
    const { carrier } = this.state

    const data = { ...values, carrier }

    this.props.tryUpdateSelf(data)
  }

  render() {
    const { carrier } = this.state
    const { auth } = this.props

    return (
      <Row className={'report-waste-form-outer-container'}>
        <Col span={24}>
          <h1>Aktuellt konto</h1>
          <Section
            header={'Inloggad användares uppgifter'}
            size={'md'}
            className={'sub-container'}
          >
            <Form
              layout={'vertical'}
              onFinish={(values) => {
                this.savePersonal(values)
              }}
              initialValues={{
                first_name: auth.user.first_name,
                last_name: auth.user.last_name,
                ssn: auth.user.ssn,
                email: auth.user.email,
                phone: auth.user.phone
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Förnamn'}
                    name={'first_name'}
                    rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
                  >
                    <Input placeholder={'Förnamn'} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Efternamn'}
                    name={'last_name'}
                    rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
                  >
                    <Input placeholder={'Förnamn'} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Personnummer'}
                    name={'ssn'}
                    rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
                  >
                    <Input placeholder={'Personnummer'} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Epostadress'}
                    name={'email'}
                    rules={[
                      { required: true, message: 'Obligatoriskt fält.' },

                      {
                        validator: (_, values) => {
                          if (values && !Constants.REGEX.EMAIL.test(values)) {
                            return Promise.reject('Ogiltig emailadress.')
                          }
                          return Promise.resolve()
                        }
                      }
                    ]}
                  >
                    <Input placeholder={'Epostadress'} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Telefonnummer'}
                    name={'phone'}
                    rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
                  >
                    <Input placeholder={'Telefonnummer'} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item label={'Är transportör?'}>
                    <Checkbox
                      checked={carrier}
                      onChange={(e) =>
                        this.setState({ carrier: e.target.checked })
                      }
                    >
                      Ja
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} xl={8}>
                  <Button htmlType={'submit'}>Spara</Button>
                </Col>
              </Row>
            </Form>
          </Section>

          <Section header={'Lösenord'} size={'md'} className={'sub-container'}>
            <Form
              layout={'vertical'}
              onFinish={(values) => {
                const data = {
                  password: values.password
                }

                this.props.tryUpdateSelf(data)
                this.form.current.resetFields()
              }}
              initialValues={{
                password: '',
                repeat_password: ''
              }}
              ref={this.form}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Lösenord'}
                    name={'password'}
                    rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
                  >
                    <Input placeholder={'Lösenord'} type={'password'} />
                  </Form.Item>
                </Col>

                <Col xs={24} xl={8}>
                  <Form.Item
                    label={'Upprepa lösenord'}
                    name={'repeat_password'}
                    rules={[
                      { required: true, message: 'Obligatoriskt fält.' },
                      {
                        validator: (_, values) => {
                          if (
                            values &&
                            values !==
                              this.form.current.getFieldsValue().password
                          ) {
                            return Promise.reject('Lösenorden matchar inte.')
                          }
                          return Promise.resolve()
                        }
                      }
                    ]}
                  >
                    <Input placeholder={'Upprepa lösenord'} type={'password'} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} xl={8}>
                  <Button htmlType={'submit'}>Byt lösenord</Button>
                </Col>
              </Row>
            </Form>
          </Section>
        </Col>
      </Row>
    )
  }
}
function mapStateToProps({ auth }) {
  return {
    auth
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountSettings))
