import React, {useState, useRef, useEffect} from 'react'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { Button as AntdButton, Upload } from 'antd'
import { Layout, Content, Row, Col, Form, Switch } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import Button from 'components/common/Button'
import Section from 'components/common/Section'
import Input from 'components/common/Input'
import { EditOutlined, CameraOutlined, SendOutlined } from '@ant-design/icons'
import {Camera, FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

import './style.css'


const CollapseSignature = ({startOpen=false, name, type, allowEdit=false, reportId, trySignReport, trySendReceipt, signature, onChange, org }) => {
  const [open, setOpen] = useState(startOpen)
  const [openOverlay, setOpenOverlay] = useState(false)
  const [openCamera, setOpenCamera] = useState(false)
  const [cameraData, setCameraData] = useState(null)
  const [signerName, setSignerName] = useState(name)
  let signCanvas = useRef(null)
  let form = useRef(null)

  const clearSignature = () => {
    signCanvas.current.clear()
  }

  const sign = () => {
    form.current.getFieldValue('signature_name')
    if (signCanvas.current.isEmpty()) {
      window.alert('Signatur saknas')
    } else {
      const signImage = signCanvas.current.getTrimmedCanvas().toDataURL('image/jpeg', 0.8)
      const name = form.current.getFieldValue('signature_name')
      trySignReport(reportId, type, {image: signImage, name: name }, org)
      setSignerName(name)
      onChange(signImage)
      setOpenOverlay(false)
    }
  }

  const saveCameraImage = () => {
    trySignReport(reportId, type, {image: cameraData}, org)
    onChange(cameraData)
    setOpenCamera(false)
  }
  
  function handleTakePhoto (dataUri) {
    setCameraData(dataUri)
  }

  function sendReceipt() {
    const email = prompt('Ange en e-post dit kvittot skall skickas. (Adressen sparas ej)', '')
    if (email) {
      trySendReceipt(reportId, email)
    }
  }


  return (
    <div className="signature-wrapper">
      <div className="collapse-arrow">
        { open && <DownOutlined onClick={() => setOpen(!open)}/> }
        { !open && <RightOutlined onClick={() => setOpen(!open)}/> }
      </div>
      <div className="collapse-content">
        <Row>
          <Col span={24}>
            { signature && 
              <img className="signature-image"src={signature} />
            }
            { !open &&
              <p>{signerName}</p>
            }
            { open && allowEdit

            }
            { open && !allowEdit && 
              <p>{signerName}</p>
            }
          </Col>
        </Row>
        { open &&
          <>
            <Row gutter={16}>
              <Col>
                <AntdButton onClick={() => setOpenOverlay(!openOverlay)}><EditOutlined/> Signera</AntdButton>
              </Col>
              <Col>
                <AntdButton onClick={() => setOpenCamera(!openCamera)}><CameraOutlined />Ta bild</AntdButton>
              </Col>
              { signature && 
                <Col>
                  <AntdButton onClick={sendReceipt}><SendOutlined />Skicka kvitto</AntdButton>
                </Col>
              }
            </Row>
            { openOverlay &&
              <div className="signature-modal">
                <div className="signature-content">
                  <div className="signature-close">
                    <AntdButton onClick={() => setOpenOverlay(false)}>X Stäng</AntdButton>
                  </div>
                  <Section header={'Signatur'}>
                    <Form initialValues={{ signature_name: name }} ref={form} >
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label={'Namn'}
                            name={'signature_name'}
                          >
                            <Input
                              placeholder={'Nampförtydligande'}
                              id={'xxxxxxxxx'}
                            />
                          </Form.Item>                                  
                        </Col>
                      </Row> 
                      {/* <Row>
                        <Col span={24}>
                          <Form.Item
                            label={'E-post'}
                            name={'email'}
                          >
                            <Input
                              placeholder={'Ange e-post adress'}
                              id={'yyyyyyy'}
                            />
                          </Form.Item>                                  
                        </Col>
                      </Row>                          */}
                      <Row>
                        <Col span={24}>
                          <div className="signature-container">
                            <SignatureCanvas penColor='black' ref={signCanvas} backgroundColor='rgb(255, 255, 255)'
                              canvasProps={{width: 1000, height: 150, className: 'sigCanvas'}} />
                          </div>                      
                          <div className="signature-buttons">
                            <AntdButton onClick={() => clearSignature()}>Rensa signatur</AntdButton>

                            <Form.Item>
                              <Button onClick={() => sign()}>Spara</Button>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>                   
                    </Form>  
                  </Section>
                </div>

              </div>
            }
            { openCamera &&
              <div className="camera-modal">
                {
                  cameraData ? 
                    <>
                      <img className="camera-thumb" src={cameraData} />
                      <div className="camera-buttons">
                        <Button onClick={() => saveCameraImage()}>Använd denna</Button>
                        <AntdButton onClick={() => setCameraData(null)}>Ta om</AntdButton>
                      </div>
                    </>
                    :
                    <Camera
                      imageType = { IMAGE_TYPES.JPG }
                      idealFacingMode = { FACING_MODES.ENVIRONMENT }
                      idealResolution = {{ width: 200, height: 200}}
                      onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri) } } 
                    />
                }
              </div>
            }
          </>
        }

      </div>                
    </div>
  )
}

export default CollapseSignature