import ShortId from 'shortid'
import * as Functions from 'helpers/Functions'

export const OPERATION_TEMPLATES = 'OPERATION_TEMPLATES'
export const TRANSPORTER_TEMPLATES = 'TRANSPORTER_TEMPLATES'
export const RECEIVER_TEMPLATES = 'RECEIVER_TEMPLATES'
export const WASTE_TYPE_TEMPLATES = 'WASTE_TYPE_TEMPLATES'

export const MUNICIPALITY_CODES = {
  1060: { name: '1060, Olofström', code: '1060' },
  1080: { name: '1080, Karlskrona', code: '1080' },
  1081: { name: '1081, Ronneby', code: '1081' },
  1082: { name: '1082, Karlshamn', code: '1082' },
  1083: { name: '1083, Sölvesborg', code: '1083' },
  1214: { name: '1214, Svalöv', code: '1214' },
  1230: { name: '1230, Staffanstorp', code: '1230' },
  1231: { name: '1231, Burlöv', code: '1231' },
  1233: { name: '1233, Vellinge', code: '1233' },
  1256: { name: '1256, Östra Göinge', code: '1256' },
  1257: { name: '1257, Örkelljunga', code: '1257' },
  1260: { name: '1260, Bjuv', code: '1260' },
  1261: { name: '1261, Kävlinge', code: '1261' },
  1262: { name: '1262, Lomma', code: '1262' },
  1263: { name: '1263, Svedala', code: '1263' },
  1264: { name: '1264, Skurup', code: '1264' },
  1265: { name: '1265, Sjöbo', code: '1265' },
  1266: { name: '1266, Hörby', code: '1266' },
  1267: { name: '1267, Höör', code: '1267' },
  1270: { name: '1270, Tomelilla', code: '1270' },
  1272: { name: '1272, Bromölla', code: '1272' },
  1273: { name: '1273, Osby', code: '1273' },
  1275: { name: '1275, Perstorp', code: '1275' },
  1276: { name: '1276, Klippan', code: '1276' },
  1277: { name: '1277, Åstorp', code: '1277' },
  1278: { name: '1278, Båstad', code: '1278' },
  1280: { name: '1280, Malmö', code: '1280' },
  1281: { name: '1281, Lund', code: '1281' },
  1282: { name: '1282, Landskrona', code: '1282' },
  1283: { name: '1283, Helsingborg', code: '1283' },
  1284: { name: '1284, Höganäs', code: '1284' },
  1285: { name: '1285, Eslöv', code: '1285' },
  1286: { name: '1286, Ystad', code: '1286' },
  1287: { name: '1287, Trelleborg', code: '1287' },
  1290: { name: '1290, Kristianstad', code: '1290' },
  1291: { name: '1291, Simrishamn', code: '1291' },
  1292: { name: '1292, Ängelholm', code: '1292' },
  1293: { name: '1293, Hässleholm', code: '1293' },
  1315: { name: '1315, Hylte', code: '1315' },
  1380: { name: '1380, Halmstad', code: '1380' },
  1381: { name: '1381, Laholm', code: '1381' },
  1382: { name: '1382, Falkenberg', code: '1382' },
  1383: { name: '1383, Varberg', code: '1383' },
  1384: { name: '1384, Kungsbacka', code: '1384' },
  1401: { name: '1401, Härryda', code: '1401' },
  1402: { name: '1402, Partille', code: '1402' },
  1407: { name: '1407, Öckerö', code: '1407' },
  1415: { name: '1415, Stenungsund', code: '1415' },
  1419: { name: '1419, Tjörn', code: '1419' },
  1421: { name: '1421, Orust', code: '1421' },
  1427: { name: '1427, Sotenäs', code: '1427' },
  1430: { name: '1430, Munkedal', code: '1430' },
  1435: { name: '1435, Tanum', code: '1435' },
  1438: { name: '1438, Dals-Ed', code: '1438' },
  1439: { name: '1439, Färgelanda', code: '1439' },
  1440: { name: '1440, Ale', code: '1440' },
  1441: { name: '1441, Lerum', code: '1441' },
  1442: { name: '1442, Vårgårda', code: '1442' },
  1443: { name: '1443, Bollebygd', code: '1443' },
  1444: { name: '1444, Grästorp', code: '1444' },
  1445: { name: '1445, Essunga', code: '1445' },
  1446: { name: '1446, Karlsborg', code: '1446' },
  1447: { name: '1447, Gullspång', code: '1447' },
  1452: { name: '1452, Tranemo', code: '1452' },
  1460: { name: '1460, Bengtsfors', code: '1460' },
  1461: { name: '1461, Mellerud', code: '1461' },
  1462: { name: '1462, Lilla Edet', code: '1462' },
  1463: { name: '1463, Mark', code: '1463' },
  1465: { name: '1465, Svenljunga', code: '1465' },
  1466: { name: '1466, Herrljunga', code: '1466' },
  1470: { name: '1470, Vara', code: '1470' },
  1471: { name: '1471, Götene', code: '1471' },
  1472: { name: '1472, Tibro', code: '1472' },
  1473: { name: '1473, Töreboda', code: '1473' },
  1480: { name: '1480, Göteborg', code: '1480' },
  1481: { name: '1481, Mölndal', code: '1481' },
  1482: { name: '1482, Kungälv', code: '1482' },
  1484: { name: '1484, Lysekil', code: '1484' },
  1485: { name: '1485, Uddevalla', code: '1485' },
  1486: { name: '1486, Strömstad', code: '1486' },
  1487: { name: '1487, Vänersborg', code: '1487' },
  1488: { name: '1488, Trollhättan', code: '1488' },
  1489: { name: '1489, Alingsås', code: '1489' },
  1490: { name: '1490, Borås', code: '1490' },
  1491: { name: '1491, Ulricehamn', code: '1491' },
  1492: { name: '1492, Åmål', code: '1492' },
  1493: { name: '1493, Mariestad', code: '1493' },
  1494: { name: '1494, Lidköping', code: '1494' },
  1495: { name: '1495, Skara', code: '1495' },
  1496: { name: '1496, Skövde', code: '1496' },
  1497: { name: '1497, Hjo', code: '1497' },
  1498: { name: '1498, Tidaholm', code: '1498' },
  1499: { name: '1499, Falköping', code: '1499' },
  1715: { name: '1715, Kil', code: '1715' },
  1730: { name: '1730, Eda', code: '1730' },
  1737: { name: '1737, Torsby', code: '1737' },
  1760: { name: '1760, Storfors', code: '1760' },
  1761: { name: '1761, Hammarö', code: '1761' },
  1762: { name: '1762, Munkfors', code: '1762' },
  1763: { name: '1763, Forshaga', code: '1763' },
  1764: { name: '1764, Grums', code: '1764' },
  1765: { name: '1765, Årjäng', code: '1765' },
  1766: { name: '1766, Sunne', code: '1766' },
  1780: { name: '1780, Karlstad', code: '1780' },
  1781: { name: '1781, Kristinehamn', code: '1781' },
  1782: { name: '1782, Filipstad', code: '1782' },
  1783: { name: '1783, Hagfors', code: '1783' },
  1784: { name: '1784, Arvika', code: '1784' },
  1785: { name: '1785, Säffle', code: '1785' },
  1814: { name: '1814, Lekeberg', code: '1814' },
  1860: { name: '1860, Laxå', code: '1860' },
  1861: { name: '1861, Hallsberg', code: '1861' },
  1862: { name: '1862, Degerfors', code: '1862' },
  1863: { name: '1863, Hällefors', code: '1863' },
  1864: { name: '1864, Ljusnarsberg', code: '1864' },
  1880: { name: '1880, Örebro', code: '1880' },
  1881: { name: '1881, Kumla', code: '1881' },
  1882: { name: '1882, Askersund', code: '1882' },
  1883: { name: '1883, Karlskoga', code: '1883' },
  1884: { name: '1884, Nora', code: '1884' },
  1885: { name: '1885, Lindesberg', code: '1885' },
  1904: { name: '1904, Skinnskatteberg', code: '1904' },
  1907: { name: '1907, Surahammar', code: '1907' },
  1960: { name: '1960, Kungsör', code: '1960' },
  1961: { name: '1961, Hallstahammar', code: '1961' },
  1962: { name: '1962, Norberg', code: '1962' },
  1980: { name: '1980, Västerås', code: '1980' },
  1981: { name: '1981, Sala', code: '1981' },
  1982: { name: '1982, Fagersta', code: '1982' },
  1983: { name: '1983, Köping', code: '1983' },
  1984: { name: '1984, Arboga', code: '1984' },
  2021: { name: '2021, Vansbro', code: '2021' },
  2023: { name: '2023, Malung-Sälen', code: '2023' },
  2026: { name: '2026, Gagnef', code: '2026' },
  2029: { name: '2029, Leksand', code: '2029' },
  2031: { name: '2031, Rättvik', code: '2031' },
  2034: { name: '2034, Orsa', code: '2034' },
  2039: { name: '2039, Älvdalen', code: '2039' },
  2061: { name: '2061, Smedjebacken', code: '2061' },
  2062: { name: '2062, Mora', code: '2062' },
  2080: { name: '2080, Falun', code: '2080' },
  2081: { name: '2081, Borlänge', code: '2081' },
  2082: { name: '2082, Säter', code: '2082' },
  2083: { name: '2083, Hedemora', code: '2083' },
  2084: { name: '2084, Avesta', code: '2084' },
  2085: { name: '2085, Ludvika', code: '2085' },
  2101: { name: '2101, Ockelbo', code: '2101' },
  2104: { name: '2104, Hofors', code: '2104' },
  2121: { name: '2121, Ovanåker', code: '2121' },
  2132: { name: '2132, Nordanstig', code: '2132' },
  2161: { name: '2161, Ljusdal', code: '2161' },
  2180: { name: '2180, Gävle', code: '2180' },
  2181: { name: '2181, Sandviken', code: '2181' },
  2182: { name: '2182, Söderhamn', code: '2182' },
  2183: { name: '2183, Bollnäs', code: '2183' },
  2184: { name: '2184, Hudiksvall', code: '2184' },
  2260: { name: '2260, Ånge', code: '2260' },
  2262: { name: '2262, Timrå', code: '2262' },
  2280: { name: '2280, Härnösand', code: '2280' },
  2281: { name: '2281, Sundsvall', code: '2281' },
  2282: { name: '2282, Kramfors', code: '2282' },
  2283: { name: '2283, Sollefteå', code: '2283' },
  2284: { name: '2284, Örnsköldsvik', code: '2284' },
  2303: { name: '2303, Ragunda', code: '2303' },
  2305: { name: '2305, Bräcke', code: '2305' },
  2309: { name: '2309, Krokom', code: '2309' },
  2313: { name: '2313, Strömsund', code: '2313' },
  2321: { name: '2321, Åre', code: '2321' },
  2326: { name: '2326, Berg', code: '2326' },
  2361: { name: '2361, Härjedalen', code: '2361' },
  2380: { name: '2380, Östersund', code: '2380' },
  2401: { name: '2401, Nordmaling', code: '2401' },
  2403: { name: '2403, Bjurholm', code: '2403' },
  2404: { name: '2404, Vindeln', code: '2404' },
  2409: { name: '2409, Robertsfors', code: '2409' },
  2417: { name: '2417, Norsjö', code: '2417' },
  2418: { name: '2418, Malå', code: '2418' },
  2421: { name: '2421, Storuman', code: '2421' },
  2422: { name: '2422, Sorsele', code: '2422' },
  2425: { name: '2425, Dorotea', code: '2425' },
  2460: { name: '2460, Vännäs', code: '2460' },
  2462: { name: '2462, Vilhelmina', code: '2462' },
  2463: { name: '2463, Åsele', code: '2463' },
  2480: { name: '2480, Umeå', code: '2480' },
  2481: { name: '2481, Lycksele', code: '2481' },
  2482: { name: '2482, Skellefteå', code: '2482' },
  2505: { name: '2505, Arvidsjaur', code: '2505' },
  2506: { name: '2506, Arjeplog', code: '2506' },
  2510: { name: '2510, Jokkmokk', code: '2510' },
  2513: { name: '2513, Överkalix', code: '2513' },
  2514: { name: '2514, Kalix', code: '2514' },
  2518: { name: '2518, Övertorneå', code: '2518' },
  2521: { name: '2521, Pajala', code: '2521' },
  2523: { name: '2523, Gällivare', code: '2523' },
  2560: { name: '2560, Älvsbyn', code: '2560' },
  2580: { name: '2580, Luleå', code: '2580' },
  2581: { name: '2581, Piteå', code: '2581' },
  2582: { name: '2582, Boden', code: '2582' },
  2583: { name: '2583, Haparanda', code: '2583' },
  2584: { name: '2584, Kiruna', code: '2584' },
  '0114': { name: '0114, Upplands Väsby', code: '0114' },
  '0115': { name: '0115, Vallentuna', code: '0115' },
  '0117': { name: '0117, Österåker', code: '0117' },
  '0120': { name: '0120, Värmdö', code: '0120' },
  '0123': { name: '0123, Järfälla', code: '0123' },
  '0125': { name: '0125, Ekerö', code: '0125' },
  '0126': { name: '0126, Huddinge', code: '0126' },
  '0127': { name: '0127, Botkyrka', code: '0127' },
  '0128': { name: '0128, Salem', code: '0128' },
  '0136': { name: '0136, Haninge', code: '0136' },
  '0138': { name: '0138, Tyresö', code: '0138' },
  '0139': { name: '0139, Upplands-Bro', code: '0139' },
  '0140': { name: '0140, Nykvarn', code: '0140' },
  '0160': { name: '0160, Täby', code: '0160' },
  '0162': { name: '0162, Danderyd', code: '0162' },
  '0163': { name: '0163, Sollentuna', code: '0163' },
  '0180': { name: '0180, Stockholm', code: '0180' },
  '0181': { name: '0181, Södertälje', code: '0181' },
  '0182': { name: '0182, Nacka', code: '0182' },
  '0183': { name: '0183, Sundbyberg', code: '0183' },
  '0184': { name: '0184, Solna', code: '0184' },
  '0186': { name: '0186, Lidingö', code: '0186' },
  '0187': { name: '0187, Vaxholm', code: '0187' },
  '0188': { name: '0188, Norrtälje', code: '0188' },
  '0191': { name: '0191, Sigtuna', code: '0191' },
  '0192': { name: '0192, Nynäshamn', code: '0192' },
  '0305': { name: '0305, Håbo', code: '0305' },
  '0319': { name: '0319, Älvkarleby', code: '0319' },
  '0330': { name: '0330, Knivsta', code: '0330' },
  '0331': { name: '0331, Heby', code: '0331' },
  '0360': { name: '0360, Tierp', code: '0360' },
  '0380': { name: '0380, Uppsala', code: '0380' },
  '0381': { name: '0381, Enköping', code: '0381' },
  '0382': { name: '0382, Östhammar', code: '0382' },
  '0428': { name: '0428, Vingåker', code: '0428' },
  '0461': { name: '0461, Gnesta', code: '0461' },
  '0480': { name: '0480, Nyköping', code: '0480' },
  '0481': { name: '0481, Oxelösund', code: '0481' },
  '0482': { name: '0482, Flen', code: '0482' },
  '0483': { name: '0483, Katrineholm', code: '0483' },
  '0484': { name: '0484, Eskilstuna', code: '0484' },
  '0486': { name: '0486, Strängnäs', code: '0486' },
  '0488': { name: '0488, Trosa', code: '0488' },
  '0509': { name: '0509, Ödeshög', code: '0509' },
  '0512': { name: '0512, Ydre', code: '0512' },
  '0513': { name: '0513, Kinda', code: '0513' },
  '0560': { name: '0560, Boxholm', code: '0560' },
  '0561': { name: '0561, Åtvidaberg', code: '0561' },
  '0562': { name: '0562, Finspång', code: '0562' },
  '0563': { name: '0563, Valdemarsvik', code: '0563' },
  '0580': { name: '0580, Linköping', code: '0580' },
  '0581': { name: '0581, Norrköping', code: '0581' },
  '0582': { name: '0582, Söderköping', code: '0582' },
  '0583': { name: '0583, Motala', code: '0583' },
  '0584': { name: '0584, Vadstena', code: '0584' },
  '0586': { name: '0586, Mjölby', code: '0586' },
  '0604': { name: '0604, Aneby', code: '0604' },
  '0617': { name: '0617, Gnosjö', code: '0617' },
  '0642': { name: '0642, Mullsjö', code: '0642' },
  '0643': { name: '0643, Habo', code: '0643' },
  '0662': { name: '0662, Gislaved', code: '0662' },
  '0665': { name: '0665, Vaggeryd', code: '0665' },
  '0680': { name: '0680, Jönköping', code: '0680' },
  '0682': { name: '0682, Nässjö', code: '0682' },
  '0683': { name: '0683, Värnamo', code: '0683' },
  '0684': { name: '0684, Sävsjö', code: '0684' },
  '0685': { name: '0685, Vetlanda', code: '0685' },
  '0686': { name: '0686, Eksjö', code: '0686' },
  '0687': { name: '0687, Tranås', code: '0687' },
  '0760': { name: '0760, Uppvidinge', code: '0760' },
  '0761': { name: '0761, Lessebo', code: '0761' },
  '0763': { name: '0763, Tingsryd', code: '0763' },
  '0764': { name: '0764, Alvesta', code: '0764' },
  '0765': { name: '0765, Älmhult', code: '0765' },
  '0767': { name: '0767, Markaryd', code: '0767' },
  '0780': { name: '0780, Växjö', code: '0780' },
  '0781': { name: '0781, Ljungby', code: '0781' },
  '0821': { name: '0821, Högsby', code: '0821' },
  '0834': { name: '0834, Torsås', code: '0834' },
  '0840': { name: '0840, Mörbylånga', code: '0840' },
  '0860': { name: '0860, Hultsfred', code: '0860' },
  '0861': { name: '0861, Mönsterås', code: '0861' },
  '0862': { name: '0862, Emmaboda', code: '0862' },
  '0880': { name: '0880, Kalmar', code: '0880' },
  '0881': { name: '0881, Nybro', code: '0881' },
  '0882': { name: '0882, Oskarshamn', code: '0882' },
  '0883': { name: '0883, Västervik', code: '0883' },
  '0884': { name: '0884, Vimmerby', code: '0884' },
  '0885': { name: '0885, Borgholm', code: '0885' },
  '0980': { name: '0980, Gotland', code: '0980' }
}

export const TEMPLATES = {
  [OPERATION_TEMPLATES]: {
    singular: 'Verksamhet',
    plural: 'Verksamheter',
    sub_header: '',
    display_name: ['name'],
    attributes: {
      name: {
        label: 'Namn på mallen',
        type: 'String',
        key: ShortId.generate(),
        hideOnConfirmation: true
      },
      organization_number: {
        label: 'Organisationsnummer',
        type: 'String',
        key: ShortId.generate(),
        validator: (val) =>
          new RegExp('^(?!<!\\d)\\d{2}[2-9](?!<!\\d)\\d{7}$').test(val),
        error: 'Organisationsnummer måste vara 10 siffror utan avgränsare.'
      },
      organization_is_swedish: {
        label: 'Organisationen är',
        type: 'Radio',
        key: ShortId.generate(),
        options: [
          { label: 'Svensk', value: true },
          { label: 'Utländsk', value: false }
        ]
      },
      operation_name: {
        label: 'Verksamhetens namn',
        type: 'String',
        key: ShortId.generate()
      },
      cfar_number: {
        label: 'Verksamhetens CFAR-nummer',
        type: 'String',
        key: ShortId.generate(),
        validator: (val) => val && val.length === 8,
        error: 'CFAR-nummer måste vara 8 siffror.'
      },
      contact_person: {
        label: 'Kontaktperson',
        type: 'String',
        key: ShortId.generate()
      },
      email: {
        label: 'E-postadress',
        type: 'String',
        key: ShortId.generate(),
        validator: (val) => REGEX.EMAIL.test(val),
        error: 'Ej giltig e-postadress.'
      },
      phone: {
        label: 'Telefon',
        type: 'String',
        key: ShortId.generate()
      }
    }
  },
  [TRANSPORTER_TEMPLATES]: {
    singular: 'Transportör',
    plural: 'Transportörer',
    display_name: ['organization_name', 'organization_number'],
    attributes: {
      organization_name: {
        label: 'Organisationsnamn',
        type: 'String',
        key: ShortId.generate(),
        hideOnConfirmation: true
      },
      organization_is_swedish: {
        label: 'Organisationen är',
        type: 'Radio',
        key: ShortId.generate(),
        options: [
          { label: 'Svensk', value: true },
          { label: 'Utländsk', value: false }
        ]
      },
      organization_number: {
        label: 'Organisationsnummer',
        type: 'String',
        key: ShortId.generate(),
        validator: (val) =>
          new RegExp('^(?!<!\\d)\\d{2}[2-9](?!<!\\d)\\d{7}$').test(val),
        error: 'Organisationsnummer måste vara 10 siffror utan avgränsare.'
      }
    }
  },
  [RECEIVER_TEMPLATES]: {
    singular: 'Mottagare',
    plural: 'Mottagare',
    display_name: ['organization_name', 'address', 'city'],
    attributes: {
      organization_name: {
        label: 'Organisationsnamn',
        type: 'String',
        key: ShortId.generate(),
        hideOnConfirmation: true
      },
      organization_number: {
        label: 'Organisationsnummer',
        type: 'String',
        key: ShortId.generate(),
        validator: (val) =>
          new RegExp('^(?!<!\\d)\\d{2}[2-9](?!<!\\d)\\d{7}$').test(val),
        error: 'Organisationsnummer måste vara 10 siffror utan avgränsare.'
      },
      organization_is_swedish: {
        label: 'Organisationen är',
        type: 'Radio',
        key: ShortId.generate(),
        options: [
          { label: 'Svensk', value: true },
          { label: 'Utländsk', value: false }
        ]
      },
      address: {
        label: 'Adress',
        type: 'String',
        key: ShortId.generate()
      },
      zip_code: {
        label: 'Postnummer',
        type: 'String',
        validator: (val) => val.length === 5 && Functions.isPositiveNumber(val),
        error: 'Postnummer måste vara 5 siffror utan mellanrum.',
        key: ShortId.generate()
      },
      city: {
        label: 'Postort',
        type: 'String',
        key: ShortId.generate()
      },
      municipality_code: {
        label: 'Kommunkod',
        type: 'Dropdown',
        key: ShortId.generate(),
        items: Object.values(MUNICIPALITY_CODES).map((item) => ({
          value: item.code,
          label: item.name
        }))
      }
    }
  },
  [WASTE_TYPE_TEMPLATES]: {
    singular: 'Avfallstyp',
    plural: 'Avfallstyper',
    display_name: ['kod', 'beskrivning'],
    attributes: {
      code: {
        label: 'Kod',
        type: 'String',
        key: ShortId.generate()
      }
    }
  }
}

export const TRANSPORTATION_TYPES = [
  {
    label: 'Vägtransport',
    value: 'Vägtransport'
  },
  {
    label: 'Järnvägstransport',
    value: 'Järnvägstransport'
  },
  {
    label: 'Sjötransport',
    value: 'Sjötransport'
  },
  {
    label: 'Flygtransport',
    value: 'Flygtransport'
  },
  {
    label: 'Inlandsjötransport',
    value: 'Inlandsjötransport'
  },
  {
    label: 'Rörledning',
    value: 'Rörledning'
  }
]

export const REGEX = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}
