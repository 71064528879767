import React from 'react'
import { Checkbox as AntdCheckbox } from 'antd'

// Overrite default antd.
import './styles.css'

const Checkbox = (props) => {
  const { onChange, children, defaultChecked, checked } = props

  return (
    <AntdCheckbox
      onChange={onChange}
      defaultChecked={defaultChecked}
      checked={checked}
    >
      {children}
    </AntdCheckbox>
  )
}

export default Checkbox
