import React, { useEffect, useState } from 'react'

import { Row, Col, Form } from 'antd'
import Button from 'components/common/Button'
import Section from 'components/common/Section'
import Input from 'components/common/Input'
import Select from 'components/common/Select'
import Checkbox from 'components/common/Checkbox'

import {getOne, getOmbudOrgs, updateOmbud, getOmbud, updateAPI} from 'actions/api/OrganizationsAPI'
import {messageSuccess, messageWarning} from 'helpers/Functions'
import * as Functions from 'helpers/Functions'

import Styles from './styles.module.css'

export default function Organizations({auth}) {
  const [form] = Form.useForm()
  const [ombudOrgs, setOmbudOrgs] = useState([{id: 0, name: 'Inget ombud valt'}]) 
  const [ombudValue, setOmbudValue] = useState(0)

  useEffect(async () => {
    const apiResponse = await getOne()
    const ombudOrgsReponse = await getOmbudOrgs()
    const ombudResponse = await getOmbud()
    setOmbudOrgs([...ombudOrgs, ...ombudOrgsReponse.data.data])
    form.setFieldsValue({
      apiKey: apiResponse.data.data.api_key,
      apiSecret: apiResponse.data.data.api_secret,
      ombud: ombudResponse.data.length > 0 ? ombudResponse.data[0].organization_id : 0,
      role: ombudResponse.data.length > 0 ? ombudResponse.data[0].role : 'admin',
      limitScope: ombudResponse.data.length > 0 ? ombudResponse.data[0].limited_view_scope : 'false'
    }) 
    setOmbudValue(ombudResponse.data.length > 0 ? ombudResponse.data[0].organization_id : 0)      
  }, [])

  async function update(values) {
    try {
      await updateAPI({data: { api_key: values.apiKey, api_secret: values.apiSecret}})
      await updateOmbud(values.ombud, values.role, values.limitScope)

      messageSuccess('Organisationen har uppdaterats')
    } catch(err) {
      messageWarning('Organisationen uppdaterades inte')
    }
  }

  return (
    <Row>
      <Col span={24}>
        <h1>Organisation</h1>
        <Section header={''} size={'md'}>
          <Form
            form={form}
            layout={'vertical'}
            initialValues={{ apiKey: '', apiSecret: '', ombud: 0, limitScope: false, role: 'admin'}}
            onFinish={(values) => {
              update(values)
            }}
          >
            <Form.Item label={'API-key'} name={'apiKey'}>
              <Input
                placeholder={'Lägg in din organisations API-nyckel.'}
              />
            </Form.Item>

            <Form.Item label={'API-secret'} name={'apiSecret'}>
              <Input
                placeholder={'Lägg in din organisations API-secret.'}
              />
            </Form.Item>            

            { Functions.getOmbudOrgId(auth) === null &&
           <>
             <Form.Item label={'Ombud'} name={'ombud'}>
               <Select onChange={(value) => setOmbudValue(value)} options={ombudOrgs.map(org => ({value: org.id, label: org.name}))} />
             </Form.Item>
             { ombudValue !== 0 && 
            <>
              <Form.Item label={'Roll'} name={'role'}>
                <Select options={[{value: 'admin', label: 'Avfallsproducent'}, {value: 'carrier', label: 'Transportör'}]} />
              </Form.Item>

              <Form.Item label={'Begränsa åtkomst till rapporter som ombudet själv skapat'} name="limitScope" valuePropName="checked">
                <Checkbox>Ja</Checkbox>
              </Form.Item>
            </>
             }
           </>
            }
            <Button htmlType={'submit'} className={Styles.button}>
              Uppdatera
            </Button>
          </Form>
        </Section>
      </Col>
    </Row>
  )
}