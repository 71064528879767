import React from 'react'
import './style.css'

const RadioButton = ({ onChange, className, checked, items = [] }) => (
  <div className={`radio-container ${className}`}>
    {items.map((item, index) => (
      <div className={'radio'} onClick={() => onChange(item)} key={index}>
        <div
          className={`radio-button ${item.value === checked ? 'active' : ''}`}
        />
        <p>{item.label}</p>
      </div>
    ))}
  </div>
)
RadioButton.displayName = 'RadioButton'

export default RadioButton
