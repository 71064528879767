export const setAccessToken = (token) => {
  localStorage.setItem('accessToken', token)
}

export const getAccessToken = () => {
  return localStorage.getItem('accessToken')
}

export const deleteAccessToken = () => {
  return localStorage.removeItem('accessToken')
}

export const setUserType = (isAdmin) => {
  localStorage.setItem('userType', isAdmin ? 'admin' : 'user')
}

export const getUserType = () => {
  return localStorage.getItem('userType')
}

export const deleteUserType = () => {
  return localStorage.removeItem('userType')
}
