import * as Types from 'actions/Types'
import * as Api from 'actions/api/WasteTypeTemplates'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllWasteTypeTemplates(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_WASTE_TYPE_TEMPLATES
    })

    Api.getAll(orgId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_WASTE_TYPE_TEMPLATES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_WASTE_TYPE_TEMPLATES_FAILED
        })
      })
  }
}

export function tryCreateWasteTypeTemplate(data) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_WASTE_TYPE_TEMPLATE
    })

    Api.create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_WASTE_TYPE_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Avfallstypmallen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_WASTE_TYPE_TEMPLATE_FAILED
        })

        messageWarning('Avfallstypmallen kunde inte skapas')
      })
  }
}

export function tryUpdateWasteTypeTemplate(id, data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_WASTE_TYPE_TEMPLATE
    })

    Api.update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_WASTE_TYPE_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Avfallstypmallen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_WASTE_TYPE_TEMPLATE_FAILED
        })

        messageWarning('Avfallstypmallen kunde inte uppdateras')
      })
  }
}

export function tryDeleteWasteTypeTemplate(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_WASTE_TYPE_TEMPLATE
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_WASTE_TYPE_TEMPLATE_SUCCESS,
          payload: id
        })

        messageSuccess('Avfallstypmallen har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_WASTE_TYPE_TEMPLATE_FAILED
        })

        messageWarning('Avfallstypmallen kunde inte tas bort')
      })
  }
}

export function tryEmptyWasteTypeTemplates() {
  return (dispatch) => {
    dispatch({
      type: Types.EMPTY_ALL_WASTE_TYPE_TEMPLATES
    })
  }
}
