import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Styles from './styles.module.css'

const Badge = ({ header, information, icon }) => {
  return (
    <div className={Styles['container']}>
      <h4 className={Styles['header']}>
        <FontAwesomeIcon icon={icon} className={Styles['icon']} />
        {header}
      </h4>
      <p className={Styles['information']}>{information}</p>
    </div>
  )
}

Badge.displayName = 'Badge'

export default Badge
