import * as Types from 'actions/Types'
import * as Api from 'actions/api/Users'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryCreateUser(data) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_USER
    })

    Api.create(data)
      .then((response) => {
        dispatch({
          type: Types.CREATE_USER_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Användare skapad')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_USER_FAILED
        })

        messageWarning('Kunde inte skapa användare')
      })
  }
}

export function tryGetAllUsers(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_USERS
    })

    Api.getAll(orgId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_USERS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_USERS_FAILED
        })
      })
  }
}

export function tryDeleteUser(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_USER
    })

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_USER_SUCCESS,
          payload: id
        })

        messageSuccess('Användare borttagen')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_USER_FAILED
        })

        messageWarning('Kunde inte ta bort användare')
      })
  }
}

export function tryUpdateSelf(data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_SELF
    })

    Api.updateSelf({ data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_SELF_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Kontouppgiter uppdaterade')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_SELF_FAILED
        })

        messageWarning('Kunde inte uppdatera kontouppgiterna')
      })
  }
}

export function tryEmptyUsers() {
  return (dispatch) => {
    dispatch({
      type: Types.EMPTY_USERS
    })
  }
}