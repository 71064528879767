import Axios from 'axios'
import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/auth/'
  : `${process.env.REACT_APP_API}auth/`

export function localLogin(user) {
  const url = `${BASE_URL}local-login`

  return Axios.post(url, { data: user })
}

export function authorize() {
  const url = `${BASE_URL}authorize`

  return Axios.post(url, {}, Functions.getAuthenticationHeaders())
}

export function loginAdmin(user) {
  const url = `${BASE_URL}admin-login`

  return Axios.post(url, { data: user })
}

export function authorizeAdmin() {
  const url = `${BASE_URL}admin-authorize`

  return Axios.post(url, {}, Functions.getAuthenticationHeaders())
}

export function forgotPassword(email, admin) {
  const url = `${BASE_URL}forgot-password`

  return Axios.post(url, { data: email, admin: admin ? true : false })
}

export function verifyToken(token) {
  const url = `${BASE_URL}forgot-password/${token}`

  return Axios.get(url)
}

export function updatePassword(token, password) {
  const url = `${BASE_URL}forgot-password/${token}`

  return Axios.patch(url, { data: password })
}
