import * as Types from 'actions/Types'
import * as Api from 'actions/api/OperationTemplates'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllOperationTemplates(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_OPERATION_TEMPLATES
    })

    Api.getAll(orgId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_OPERATION_TEMPLATES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_OPERATION_TEMPLATES_FAILED
        })
      })
  }
}

export function tryCreateOperationTemplate(data) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_OPERATION_TEMPLATE
    })

    Api.create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_OPERATION_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Verksamhetsmallen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_OPERATION_TEMPLATE_FAILED
        })

        messageWarning('Verksamhetsmallen kunde inte skapas')
      })
  }
}

export function tryUpdateOperationTemplate(id, data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_OPERATION_TEMPLATE
    })

    Api.update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_OPERATION_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Verksamhetsmallen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_OPERATION_TEMPLATE_FAILED
        })

        messageWarning('Verksamhetsmallen kunde inte uppdateras')
      })
  }
}

export function tryDeleteOperationTemplate(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_OPERATION_TEMPLATE
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_OPERATION_TEMPLATE_SUCCESS,
          payload: id
        })

        messageSuccess('Verksamhetsmallen har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_OPERATION_TEMPLATE_FAILED
        })

        messageWarning('Verksamhetsmallen kunde inte tas bort')
      })
  }
}

export function tryEmptyOperationTemplates() {
  return (dispatch) => {
    dispatch({
      type: Types.EMPTY_OPERATION_TEMPLATES
    })
  }
}
