import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Row, Col, Modal, Form, Checkbox } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Table from 'components/common/Table'
import Divider from 'components/common/Divider'

import * as AuthActions from 'actions/Authentication'
import * as OrganizationActions from 'actions/Organizations'
import * as AdminUserActions from 'actions/AdminUsers'
import * as AlertActions from 'actions/Alert'

import * as Constants from 'helpers/Constants'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Admin = ({
  signOut,
  auth,
  organizations,
  tryGetAllOrganizations,
  tryCreateOrganization,
  tryUpdateOrganization,
  tryDeleteOrganization,
  tryUpdateAdmin,
  setAlert,
  resetAlert
}) => {
  const [page, setPage] = useState(1)
  const [addCompany, setAddCompany] = useState(false)
  const [editCompany, setEditCompany] = useState(0)

  const formRef = useRef(null)
  const emailFormRef = useRef(null)
  const passwordFormRef = useRef(null)

  if (!organizations.fetched) {
    tryGetAllOrganizations(auth)
  }

  return (
    <Row>
      <Col span={24} className={Styles['admin-header']}>
        <h1>Alla företag</h1>
        <Button
          onClick={() => setAddCompany(true)}
          className={Styles['add-company']}
        >
          Lägg till företag
        </Button>
        <Button className={Styles['sign-out']} onClick={() => signOut()}>
          <FontAwesomeIcon
            icon={faSignOutAlt}
            className={Styles['sign-out-icon']}
          />{' '}
          Logga ut
        </Button>
      </Col>

      <Col span={24}>
        <Table
          headers={Conf.HEADERS(
            (id) => tryDeleteOrganization(id),
            (org) => setEditCompany(org),
            setAlert,
            resetAlert
          )}
          rows={Object.values(organizations.data)}
          pagination={{ current: page, pageSize: 25 }}
          onChange={(pagination) => setPage(pagination.current)}
        />
      </Col>

      <AddCompany
        addCompany={addCompany}
        setAddCompany={setAddCompany}
        formRef={formRef}
        tryCreateOrganization={tryCreateOrganization}
      />
      { editCompany &&
        <EditCompany
          editCompany={editCompany}
          setEditCompany={setEditCompany}
          formRef={formRef}
          tryUpdateOrganization={tryUpdateOrganization}
        />
      }
      <Divider />
      <Form
        layout="vertical"
        onFinish={(values) => {
          tryUpdateAdmin({ data: { email: values.email } })
        }}
        initialValues={{ email: auth.user.email }}
        ref={emailFormRef}
        className={Styles['email-form']}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} className={Styles['add-company']}>
            Uppdatera email
          </Button>
        </Form.Item>
      </Form>
      <Form
        layout="vertical"
        onFinish={(values) => {
          tryUpdateAdmin({ data: { password: values.password } })
          passwordFormRef.current.resetFields()
        }}
        ref={passwordFormRef}
        className={Styles['password-form']}
      >
        <Form.Item
          label="Lösenord"
          name="password"
          rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
        >
          <Input type={'password'} />
        </Form.Item>
        <Form.Item
          label="Upprepa Lösenord"
          name="repeatPassword"
          rules={[
            { required: true, message: 'Obligatoriskt fält.' },
            {
              validator: (_, values) => {
                if (
                  values &&
                  values !== passwordFormRef.current.getFieldsValue().password
                ) {
                  return Promise.reject('Lösenorden matchar inte.')
                }

                return Promise.resolve()
              }
            }
          ]}
        >
          <Input type={'password'} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} className={Styles['add-company']}>
            Uppdatera lösenord
          </Button>
        </Form.Item>
      </Form>
    </Row>
  )
}

const AddCompany = ({
  addCompany,
  setAddCompany,
  formRef,
  tryCreateOrganization
}) => {
  const [external, setExternal] = useState(false)
  const [isOmbud, setIsOmbud] = useState(false)

  return (
    <Modal
      visible={addCompany}
      onCancel={() => {
        setAddCompany(false)
        formRef.current.resetFields()
      }}
      footer={null}
      width={'60%'}
    >
      <Form
        layout="vertical"
        onFinish={async (values) => {
          values.external = external
          values.is_ombud = isOmbud          
          await tryCreateOrganization({ ...values })
          setAddCompany(false)
          formRef.current.resetFields()
        }}
        ref={formRef}
      >
        <h2>Lägg till företag</h2>
        <Divider />
        <Form.Item
          label="Företagsnamn"
          name="organization_name"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Organisationsnummer"
          name="organization_number"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={'isOmbud'} label={'Kan bolaget agera ombud för annat företag?'}>
          <Checkbox checked={isOmbud} onChange={() => setIsOmbud(!isOmbud)}>
              Ja
          </Checkbox>
        </Form.Item>          
        <Divider />
        <h4>Användarinformation</h4>
        <p className={Styles['user-info']}>
          Varje företag måste ha en initial användare.
        </p>
        <Form.Item
          label="Förnamn"
          name="first_name"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Efternamn"
          name="last_name"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Telefonnummer"
          name="phone"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Obligatoriskt fält' },
            {
              validator: (_, value) => {
                if (!Constants.REGEX.EMAIL.test(value)) {
                  return Promise.reject('Email är på fel format')
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={'external'} label={'Är extern användare?'}>
          <Checkbox checked={external} onChange={() => setExternal(!external)}>
              Ja
          </Checkbox>
        </Form.Item>        
        {
          external &&
          <Form.Item
            label="Lösenord"
            name="password"
            rules={[{ required: external, message: 'Obligatoriskt fält' }]}
          >
            <Input type={'password'} />
          </Form.Item>
        }
        <Divider />
        <Form.Item>
          <Button htmlType={'submit'} className={Styles['add-company']}>
            Lägg till
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const EditCompany = ({
  editCompany,
  setEditCompany,
  formRef,
  tryUpdateOrganization
}) => {
  const [isOmbud, setIsOmbud] = useState(editCompany.is_ombud ? true : false)

  console.log(editCompany)
  return (
    <Modal
      visible={editCompany}
      onCancel={() => {
        setEditCompany(0)
        formRef.current.resetFields()
      }}
      footer={null}
      width={'60%'}
    >
      <Form
        layout="vertical"
        onFinish={async (values) => {
          values.is_ombud = isOmbud          
          await tryUpdateOrganization({ id: editCompany.id, ...values })
          setEditCompany(0)
          formRef.current.resetFields()
        }}
        initialValues={editCompany}
        ref={formRef}
      >
        <h2>Ändra företag</h2>
        <Divider />
        <Form.Item
          label="Företagsnamn"
          name="name"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Organisationsnummer"
          name="organization_number"
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={'isOmbud'} label={'Kan bolaget agera ombud för annat företag?'}>
          <Checkbox checked={isOmbud} onChange={() => setIsOmbud(!isOmbud)}>
            Ja
          </Checkbox>
        </Form.Item>          
        <Divider />
        <Form.Item>
          <Button htmlType={'submit'} className={Styles['add-company']}>
            Uppdatera
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

function mapStateToProps({ auth, organizations }) {
  return { auth, organizations }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AuthActions,
      ...OrganizationActions,
      ...AlertActions,
      ...AdminUserActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin))
