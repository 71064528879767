import * as Types from 'actions/Types'
import * as Api from 'actions/api/TransporterTemplates'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllTransporterTemplates(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_TRANSPORTER_TEMPLATES
    })

    Api.getAll(orgId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_TRANSPORTER_TEMPLATES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_TRANSPORTER_TEMPLATES_FAILED
        })
      })
  }
}

export function tryCreateTransporterTemplate(data) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_TRANSPORTER_TEMPLATE
    })

    Api.create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_TRANSPORTER_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Transportörmallen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_TRANSPORTER_TEMPLATE_FAILED
        })

        messageWarning('Transportörmallen kunde inte skapas')
      })
  }
}

export function tryUpdateTransporterTemplate(id, data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_TRANSPORTER_TEMPLATE
    })

    Api.update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_TRANSPORTER_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Transportörmallen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_TRANSPORTER_TEMPLATE_FAILED
        })

        messageWarning('Transportörmallen kunde inte uppdateras')
      })
  }
}

export function tryDeleteTransporterTemplate(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_TRANSPORTER_TEMPLATE
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_TRANSPORTER_TEMPLATE_SUCCESS,
          payload: id
        })

        messageSuccess('Transportörmallen har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_TRANSPORTER_TEMPLATE_FAILED
        })

        messageWarning('Transportörmallen kunde inte tas bort')
      })
  }
}

export function tryEmptyTransporterTemplates() {
  return (dispatch) => {
    dispatch({
      type: Types.EMPTY_TRANSPORTER_TEMPLATES
    })
  }
}