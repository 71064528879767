import * as Types from 'actions/Types'
import * as Api from 'actions/api/ReceiverTemplates'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllReceiverTemplates(orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_RECEIVER_TEMPLATES
    })

    Api.getAll(orgId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_RECEIVER_TEMPLATES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_RECEIVER_TEMPLATES_FAILED
        })
      })
  }
}

export function tryCreateReceiverTemplate(data) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_RECEIVER_TEMPLATE
    })

    Api.create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_RECEIVER_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Mottagarmallen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_RECEIVER_TEMPLATE_FAILED
        })

        messageWarning('Mottagarmallen kunde inte skapas')
      })
  }
}

export function tryUpdateReceiverTemplate(id, data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_RECEIVER_TEMPLATE
    })

    Api.update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_RECEIVER_TEMPLATE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Mottagarmallen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_RECEIVER_TEMPLATE_FAILED
        })

        messageWarning('Mottagarmallen kunde inte uppdateras')
      })
  }
}

export function tryDeleteReceiverTemplate(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_RECEIVER_TEMPLATE
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_RECEIVER_TEMPLATE_SUCCESS,
          payload: id
        })

        messageSuccess('Mottagarmallen har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_RECEIVER_TEMPLATE_FAILED
        })

        messageWarning('Mottagarmallen kunde inte tas bort')
      })
  }
}

export function tryEmptyReceiverTemplates() {
  return (dispatch) => {
    dispatch({
      type: Types.EMPTY_RECEIVER_TEMPLATES
    })
  }
}