import React from 'react'
import './style.css'

const BigButton = ({ children, onClick, filled, className = '' }) => {
  return (
    <div
      className={`big-button-container ${filled ? 'filled' : ''} ${className}`}
      onClick={() => onClick()}
    >
      {children}
    </div>
  )
}
BigButton.displayName = 'BigButton'

export default BigButton
