import * as Types from 'actions/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_ALL_WASTE_TYPES_SUCCESS:
    return {
      ...state,
      data: Functions.getArrayToObject(payload, 'kod'),
      fetched: true
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
