import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import reducers from 'reducers'

import 'antd/dist/antd.css'

const loggerMiddleware = createLogger()

ReactDOM.render(
  <React.StrictMode>
    <Provider
      store={createStore(
        reducers,
        applyMiddleware(thunkMiddleware, loggerMiddleware)
      )}
    >
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
