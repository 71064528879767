import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Modal, Form } from 'antd'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import Badge from 'components/common/Badge'
import Table from 'components/common/Table'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Checkbox from 'components/common/Checkbox'

import * as UserActions from 'actions/Users'
import * as AlertActions from 'actions/Alert'
import * as Functions from 'helpers/Functions'

// Local files.
import * as Conf from './conf'
import Styles from './styles.module.css'

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      createUser: '',
      carrier: false, 
      external: false
    }

    this.usersForm = React.createRef()
  }

  componentDidMount() {
    const { users, auth } = this.props

    const ombudOrg = Functions.getOmbudOrgId(auth)

    if (!users.fetched) {
      this.props.tryGetAllUsers(ombudOrg)
    }
  }

  componentDidUpdate(prevProps) {
    const { users } = this.props

    if (!users.isCreating && prevProps.users.isCreating) {
      this.usersForm.current.resetFields()

      this.setState({ createUser: false })
    }
  }

  getRows() {
    const { users } = this.props

    return Object.values(users.data).map((user) => {
      return {
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: user.phone, 
        external: user.external ? 'x' : ''
      }
    })
  }

  render() {
    const { users, auth, setAlert, resetAlert } = this.props
    const { page, createUser, carrier, external } = this.state

    if (!users.fetched) {
      return null
    }

    return (
      <Row className={Styles['container']}>
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'}>
            <Col>
              <h1>Användare</h1>
              <p style={{paddingBottom: '10px'}}>Administratören lägger till och tar bort användare i denna vy.</p>
            </Col>
            <Col>
              <Button
                className={'display-none-max-900'}
                onClick={() => this.setState({ createUser: true })}
              >
                + Lägg till
              </Button>
            </Col>
          </Row>

          <Col span={24} className={'display-none-min-900'}>
            <Badge
              header={'Hantera användare'}
              information={'Det går ej att hantera användare i mobilläge.'}
              icon={faInfo}
            />
          </Col>

          <Col span={24} className={'display-none-max-900'}>
            <Badge
              header={'Uppdatera uppgifter'}
              information={
                'Endast en användare kan ändra sitt lösenord och övriga uppgifter. Denna sidan är enbart till för att lägga till nya användare eller ta bort existerande.'
              }
              icon={faInfo}
            />
          </Col>

          <Col span={24} className={'display-none-max-900'}>
            <Table
              headers={Conf.HEADERS(
                auth.user.id,
                (id) => this.props.tryDeleteUser(id),
                setAlert,
                resetAlert
              )}
              rows={this.getRows()}
              pagination={{ current: page, pageSize: Conf.PAGE_SIZE }}
              onChange={(pagination) =>
                this.setState({ page: pagination.current })
              }
            />
          </Col>
        </Col>
        <CreateUserModal
          open={createUser}
          onClose={() => this.setState({ createUser: false })}
          formRef={this.usersForm}
          onFinish={(values) => {
            this.props.tryCreateUser({ ...values, carrier, external })
          }}
          loading={users.isCreating}
          carrier={carrier}
          updateCarrier={() => this.setState({ carrier: !carrier })}
          external={external}
          updateExternal={() => this.setState({ external: !external })}
        />
      </Row>
    )
  }
}

const CreateUserModal = ({
  open,
  onClose,
  onFinish,
  formRef,
  loading,
  carrier,
  updateCarrier, 
  external, 
  updateExternal
}) => {
  return (
    <Modal visible={open} onCancel={onClose} footer={null}>
      <Col span={24}>
        <h1>Skapa användare</h1>
        <Form
          layout={'vertical'}
          onFinish={(values) => onFinish(values)}
          initialValues={{
            first_name: '',
            last_name: '',
            ssn: '',
            phone: '',
            email: '',
            password: ''
          }}
          ref={formRef}
        >
          <Form.Item
            label={'Förnamn'}
            name={'first_name'}
            rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
          >
            <Input placeholder={'Förnamn'} />
          </Form.Item>
          <Form.Item
            label={'Efternamn'}
            name={'last_name'}
            rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
          >
            <Input placeholder={'Efternamn'} />
          </Form.Item>
          <Form.Item
            label={'Personnummer'}
            name={'ssn'}
            rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
          >
            <Input placeholder={'Personnummer'} />
          </Form.Item>
          <Form.Item
            label={'Telefonnummer'}
            name={'phone'}
            rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
          >
            <Input placeholder={'Telefonnummer'} />
          </Form.Item>
          <Form.Item
            label={'Epostadress'}
            name={'email'}
            rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
          >
            <Input placeholder={'Epostadress'} />
          </Form.Item>
          <Form.Item name={'external'} label={'Skapa lösenord för interna och externa användare'}>
            <Checkbox checked={external} onChange={updateExternal}>
              Ja
            </Checkbox>
          </Form.Item> 
          { external &&      
            <Form.Item
              label={'Lösenord'}
              name={'password'}
              rules={[{ required: carrier, message: 'Obligatoriskt fält.' }]}
            >
              <Input placeholder={'Lösenord'} type={'password'} />
            </Form.Item>
          }          
          <Form.Item name={'carrier'} label={'Användare är endast transportör'}>
            <Checkbox checked={carrier} onChange={updateCarrier}>
              Ja
            </Checkbox>
          </Form.Item> 
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Button
                onClick={() => {
                  formRef.current.resetFields()

                  onClose()
                }}
                bordered
              >
                Avbryt
              </Button>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item>
                <Button filled htmlType={'submit'} loading={loading}>
                  Skapa
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}

function mapStateToProps({ auth, users }) {
  return { auth, users }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions, ...AlertActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users))
