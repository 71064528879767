import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Form, Button as AntdButton } from 'antd'

import Logo from 'assets/sveriges_allmannytta.svg'

import Divider from 'components/common/Divider'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Badge from 'components/common/Badge'

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import * as AuthActions from 'actions/Authentication'

import './style.css'

class Login extends Component {
  render() {
    const { auth } = this.props
    const loginUrl = `${process.env.REACT_APP_API}auth/remote-login`

    return (
      <div className={'login-page'}>
        <Row justify="center">
          <Col xxs={24} md={8}>
            <img src={Logo} />
            <h1 className={'login-header'}>Farligt avfall</h1>
            <p>
                Rapportera farligt avfall till avfallsregistret på
                Naturvårdsverket
            </p>
            {
              this.props.notRegisteredUser && 
                <div className="badge-wrapper">
                  <Badge
                    header={'Du är inte en registrerad användare'}
                    information={
                      'Er adminstratör för Farligt avfall har inte registrerat dig som användare. Kontakta denna person för att få tillgäng till tjänsten.'
                    }
                    icon={faExclamationTriangle}
                  />
                </div>
            }              
            <div className={'login-header-container'}>
              <h3>Logga in med e-post och lösenord</h3>
            </div>
            <Divider />
            <Form
              className={'login-form-container'}
              layout={'vertical'}
              initialValues={{ email: '', password: '' }}
              onFinish={(values) => {
                const { email, password } = values

                this.props.tryLocalLogin({ email, password })
              }}
            >
              <Form.Item
                label={'Epostadress'}
                name={'email'}
                rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
              >
                <Input placeholder={'E-post'} />
              </Form.Item>
              <Form.Item
                label={'Lösenord'}
                name={'password'}
                rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
              >
                <Input placeholder={'Lösenord'} type={'password'} />
              </Form.Item>
              <Form.Item>
                <Button htmlType={'submit'}>Logga in</Button>
              </Form.Item>
              {auth.error ? (
                <p className={'error-text'}>{auth.error}</p>
              ) : null}
              <a className='forgot-password' href="/forgot-password">Glömt lösenord?</a>
            </Form>
            <div className={'login-header-container'}>
              <h3>Logga in via Sveriges Allmännytta medlemsportal</h3>
            </div>
            <Divider />            
            <p>
                Är du registrerad medlem hos Sveriges Allmännytta och upplagd som användare i 
                tjänsten Farligt avfall kan du logga in här. 
            </p>                
            <AntdButton className="sva-login-button" href={loginUrl}>Gå till Sveriges Allmännyttas inloggning</AntdButton>
            <div>
              
            </div>
          </Col>           
        </Row>
           
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
