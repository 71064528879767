import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { tryAuthorizeAdmin, tryAuthorize } from 'actions/Authentication'
import { tryCheckNVHealth } from 'actions/NVHealth'
import { BrowserRouter } from 'react-router-dom'

import Router from 'components/Router'
import * as Storage from 'helpers/Storage'
import * as Functions from 'helpers/Functions'

import './style.css'


class App extends Component {
  constructor() {
    super()
    this.isSso = window.location.href.split('/').slice(-1)[0] === 'sso'
    this.isNotRegistered = window.location.href.split('/').slice(-1)[0] === 'not-registered-user'
  }

  componentDidMount() {
    const accessToken = Storage.getAccessToken() || Functions.getCookie('user-access-token')
    const userType = Storage.getUserType() || Functions.getCookie('user-access-type')
    if (accessToken && userType) {
      if (userType === 'admin') {
        this.props.tryAuthorizeAdmin()
      } else {
        this.props.tryAuthorize(this.isSso)
      }
    } else {
      Storage.deleteAccessToken()
      Storage.deleteUserType()
      if (this.isSso) {
        window.location.href = `${process.env.REACT_APP_API}auth/remote-login`
      }
    } 
  }

  componentDidUpdate() {
    if (this.props.nvHealth.up === null) {
      this.props.tryCheckNVHealth()
    }
  }

  render() {
    const { auth, nvHealth } = this.props
    return (
      <div className="app">
        <BrowserRouter>
          <Router
            authenticated={auth.isAuthenticated && auth.user}
            triedSignin={auth.triedSignIn}
            isAdmin={auth.user ? auth.user.is_admin : false}
            nvHealth={nvHealth}
            user={auth.user}
            notRegisteredUser={this.isNotRegistered}
            selectCompany={ auth.isAuthenticated && auth.user && auth.user.ombudFor && auth.user.ombudFor.length > 0 }
          />
        </BrowserRouter>
      </div>
    )
  }
}

function mapStateToProps({ auth, nvHealth }) {
  return { auth, nvHealth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { tryAuthorize, tryAuthorizeAdmin, tryCheckNVHealth },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
