import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/users/'
  : `${process.env.REACT_APP_API}users/`

export function create(data) {
  return Axios.post(BASE_URL, { data }, Functions.getAuthenticationHeaders())
}

export function getAll(orgId) {
  return Axios.get(BASE_URL + (orgId ? '?org_id=' + orgId : ''), Functions.getAuthenticationHeaders())
}

export function destroy(id) {
  return Axios.delete(`${BASE_URL}${id}`, Functions.getAuthenticationHeaders())
}

export function updateSelf(data) {
  return Axios.patch(
    `${BASE_URL}self`,
    data,
    Functions.getAuthenticationHeaders()
  )
}
