import * as Types from 'actions/Types'
import * as Api from 'actions/api/Reports'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllReports(org) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_REPORTS
    })

    Api.getAll(org)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_REPORTS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_REPORTS_FAILED
        })
      })
  }
}

export function tryCreateReport(data, orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_REPORT
    })

    Api.create(data, orgId)
      .then((response) => {
        dispatch({
          type: Types.CREATE_REPORT_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Nu har du skickat in till NVV, rapporteringen kan du se i \"Alla rapporter\”')
      })
      .catch((error) => {
        dispatch({
          type: Types.CREATE_REPORT_FAILED,
          payload: error.response.data.data
        })

        messageWarning('Rapporteringen kunde inte skickas till NVV')
      })
  }
}

export function tryUpdateReport(id, data, orgId) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_REPORT
    })

    Api.update(id, data, orgId)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_REPORT_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Rapporteringen har uppdaterats')
      })
      .catch((error) => {
        dispatch({
          type: Types.UPDATE_REPORT_FAILED,
          payload: error.response.data.data
        })

        messageWarning('Rapporteringen kunde inte uppdateras')
      })
  }
}


export function trySignReport(id, type, signatureImage, org) {
  return (dispatch) => {
    dispatch({
      type: Types.SIGN_REPORT
    })
    Api.sign(id, type, signatureImage, org)
      .then((response) => {
        dispatch({
          type: Types.SIGN_REPORT_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Rapporten har signerats')
      })
      .catch((error) => {
        dispatch({
          type: Types.SIGN_REPORT_FAILED,
          payload: error.response.data.data
        })

        messageWarning('Rapporten kunde inte signerats')
      })
  }
}

export function trySendReceipt(id, email) {
  return (dispatch) => {
    dispatch({
      type: Types.SEND_REPORT
    })
    Api.sendReceipt(id, email)
      .then((response) => {
        dispatch({
          type: Types.SEND_REPORT_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Kvitto har skickats')
      })
      .catch((error) => {
        dispatch({
          type: Types.SEND_REPORT_FAILED,
          payload: error.response.data.data
        })

        messageWarning('Rapporten kunde inte skickas')
      })
  }
}

export function tryDownloadReport(id, date) {
  return (dispatch) => {
    dispatch({
      type: Types.DOWNLOAD_REPORT
    })

    Api.downloadPdf(id)
      .then((response) => {
        const fileName = `rapport-${date}.pdf`
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', fileName) //any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.DOWNLOAD_REPORT_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DOWNLOAD_REPORT_FAILED
        })
      })
  }
}

export function tryEmptyReports() {
  return (dispatch) => {
    dispatch({
      type: Types.EMPTY_REPORTS
    })
  }
}
