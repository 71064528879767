import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Form } from 'antd'

import Logo from 'assets/sveriges_allmannytta.svg'

import Divider from 'components/common/Divider'
import Button from 'components/common/Button'
import Input from 'components/common/Input'

import * as AuthActions from 'actions/Authentication'

import './style.css'

class ForgotPassword extends Component {
  render() {
    const { auth, admin } = this.props

    return (
      <div className={'login'}>
        <Row>
          <Col span={24}>
            <div className={'login-inner-container'}>
              <img src={Logo} />
              <h1 className={'login-header'}>Farligt avfall</h1>
              <p>
                Ange din e-post adress för att byta ditt lösenord
              </p>
              <div className={'login-header-container'}>
                <h3>Glömt lösenord</h3>
              </div>
              <Divider />
              <Form
                className={'login-form-container'}
                layout={'vertical'}
                initialValues={{ email: '' }}
                onFinish={(values) => {
                  const { email } = values

                  this.props.forgotPassword(email, admin)
                }}
              >
                <Col span={24}>
                  <Form.Item
                    label={'Epostadress'}
                    name={'email'}
                    rules={[{ required: true, message: 'Obligatoriskt fält.' }]}
                  >
                    <Input placeholder={'E-post'} />
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button htmlType={'submit'}>Skicka</Button>
                </Form.Item>
                { auth.success ? (
                  <Row>
                    <Col span={24}>
                      <div className={'login-inner-container'}>
                        <p className={'success-text'}>{ auth.success }</p>
                      </div>
                    </Col>
                  </Row>
                ) : null }
                { auth.error ? (
                  <Row>
                    <Col span={24}>
                      <div className={'login-inner-container'}>
                        <p className={'error-text'}>{ auth.error }</p>
                      </div>
                    </Col>
                  </Row>
                ) : null }
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
