import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/reports/'
  : `${process.env.REACT_APP_API}reports/`

export function getAll(org) {
  return Axios.get(BASE_URL + (org ? '?org_id=' + org : '') , Functions.getAuthenticationHeaders())
}

export function create(data, org) {
  return Axios.post(BASE_URL + (org ? '?org_id=' + org : '') , data, Functions.getAuthenticationHeaders())
}

export function update(id, data, org) {
  if (data.signature) {
    Axios.post(`${BASE_URL}${id}/signature` + (org ? '?org_id=' + org : ''), {image: data.signature}, Functions.getAuthenticationHeaders())
  }

  return Axios.patch(
    `${BASE_URL}${id}` + (org ? '?org_id=' + org : ''),
    data,
    Functions.getAuthenticationHeaders()
  )
}

export function downloadPdf(id) {
  const options = Functions.getAuthenticationHeaders()
  options.responseType = 'blob'

  return Axios.get(`${BASE_URL}${id}/download`, options)
}

export function sendReceipt(id, email) {
  const options = Functions.getAuthenticationHeaders()

  return Axios.get(`${BASE_URL}${id}/sendmail/${email}`, options)
}

export function sign(id, type, data, org) {
  return Axios.post(`${BASE_URL}${id}/${type}/signature` + (org ? '?org_id=' + org : ''), data, Functions.getAuthenticationHeaders())
}

export function getSignature(id, type) {
  return Axios.get(`${BASE_URL}${id}/signature/${type}`, Functions.getAuthenticationHeaders())
}
