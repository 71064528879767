import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import './style.css'


const State = ({done, text}) => {
  return (
    <div className={done ? 'done' : ''}>
      { done && <CheckCircleOutlined style={{fontSize: '24px', marginRight: '7px'}}/>} 
      <span> {text}</span>
    </div>
  )
}

export default ({ report }) => {
  return (
    <div className='report-states'>
      <State done={report.reported_by_name} text="Skickad till Naturvårdsverket" />
      <State done={report.reported_by_name} text="Signerad av lämnare" />
      <State done={report.signed_at || report.signed_transporter_at} text="Signerad av transportör" />
      <State done={report.signed_receiver_at} text="Signerad av mottagare" />       
    </div>
  )
}
