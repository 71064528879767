import React from 'react'
import { Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

import './style.css'

const SmallList = ({ items, disabled, onEdit, onDelete }) => {
  return (
    <Row>
      <Col span={24}>
        {items.map((item, index) => (
          <Row
            gutter={[16, 16]}
            key={index}
            className={'small-list-row'}
            align={'middle'}
          >
            <Col span={20}>
              <p>{item.label}</p>
            </Col>

            <Col span={4} className={'display-none-max-900'}>
              { !disabled && 
              <div className={'small-list-actions-container'}>
                <div
                  onClick={() => onEdit(item)}
                  className={'small-list-actions-icon'}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className={'small-list-icon'}
                  />
                </div>
                <div
                  onClick={() => onDelete(item)}
                  className={'small-list-actions-icon'}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className={'small-list-icon'}
                  />
                </div>                
              </div>
              }
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  )
}
SmallList.displayName = 'SmallList'

export default SmallList
