import React from 'react'

import Button from 'components/common/Button'

import Styles from './styles.module.css'

export const HEADERS = (onRemove, onEdit, setAlert, resetAlert) => [
  {
    title: 'Företag',
    dataIndex: 'name',
    sorter: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
  },
  {
    title: 'Organisationsnummer',
    dataIndex: 'organization_number',
    sorter: (a, b) => (a.organization_number > b.organization_number ? 1 : -1)
  },
  {
    title: 'Ombudsbolag',
    dataIndex: 'is_ombud',
    render: (_, {is_ombud}) => (
      <div>{is_ombud ? 'Ja' : ''}</div>
    ),
    sorter: (a, b) => (a.is_ombud > b.is_ombud ? 1 : -1)
  },
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    width: '10%',
    render: function (col) {
      return (
        <Button
          onClick={() => onEdit(col)}
          className={Styles['remove-company']}
        >
          Edit
        </Button>
      )
    }      
  },
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    width: '10%',
    render: function (col) {
      return (
        <Button
          onClick={() =>
            setAlert({
              header: 'Bekräfta borttagning',
              contentHeader: 'Ta bort organisation',
              content:
                'Är du säker på att du vill ta bort organisationen? Alla dess användare kommer även försvinna ur systemet.',
              faIcon: 'faTrash',
              okText: 'Ta bort',
              cancelText: 'Avbryt',
              onOk: () => {
                onRemove(col.id)
                resetAlert()
              }
            })
          }
          className={Styles['remove-company']}
        >
          Ta bort
        </Button>
      )
    }
  }
]
