import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Row, Col, Form, Switch } from 'antd'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import Section from 'components/common/Section'
import Select from 'components/common/Select'
import Input from 'components/common/Input'
import Button from 'components/common/Button'
import Badge from 'components/common/Badge'
import RadioButton from 'components/common/RadioButton' 

import ControlModal from 'components/containers/reportWaste/Modal'

import * as Constants from 'helpers/Constants'
import * as Functions from 'helpers/Functions'

import * as ReportActions from 'actions/Reports'
import * as AuthActions from 'actions/Authentication'
import * as AlertActions from 'actions/Alert'

import { getSignature } from 'actions/api/Reports'

import { Button as AntdButton, Upload } from 'antd'
import CollapseSignature from 'components/common/CollapseSignature'
import ReportStates from 'components/common/ReportStates'
import { EditOutlined } from '@ant-design/icons'

import './styles.css'

class ReportWasteForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      locationError: true,
      autoComplete: undefined,
      // Due to google parsing we save this in state.
      origin: {},
      confirmationModalOpen: false,
      confirmationModal: {
        date: '',
        operation: {},
        origin: {},
        receiver: {},
        reported_by: '',
        reported_by_name: '', 
        transporter: {},
        waste_types: []
      },
      signImg: null, 
      openSignature: false,
      signatureReceiverImage: null,
      signatureTransporterImage: null,
      reportType: props.report ? props.report.report_type : 'transportplanering'
    }

    this.form = React.createRef()
  }

  componentDidMount() {
    this.loadScript()

    const {signed_receiver_at, signed_transporter_at} = this.props.report || {}

    if (signed_receiver_at) {
      getSignature(this.props.report.id, 'receiver').then((result) => {
        this.setState({signatureReceiverImage: result.data.image})
      })
    }
    if (signed_transporter_at) {
      getSignature(this.props.report.id, 'transporter').then((result) => {
        this.setState({signatureTransporterImage: result.data.image})
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { reports, setAlert, resetAlert } = this.props

    // Is creating.
    if (prevProps.reports.isCreating && !reports.isCreating) {
      if (reports.errors) {
        // Alert error message.
        setAlert({
          header: 'Något gick fel...',
          content: 'Något gick fel i skapandet hos Naturvårdsverket:',
          errors: reports.errors,
          okText: 'OK',
          onOk: () => resetAlert()
        })
      } else {
        // Success, reset state.
        this.form.current.resetFields()

        this.setState({
          locationError: true,
          origin: {},
          confirmationModalOpen: false,
          confirmationModal: {
            date: '',
            operation: {},
            origin: {},
            receiver: {},
            reported_by: '',
            reported_by_name: '',
            transporter: {},
            waste_types: []
          }
        })
      }
    }
  }

  loadScript() {
    if (!process.env.REACT_APP_GOOGLE_API_KEY) {
      return
    }

    const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
    let script = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null
          this.handleScriptLoad()
        }
      }
    } else {
      script.onload = () => this.handleScriptLoad()
    }

    script.src = url

    document.getElementsByTagName('head')[0].appendChild(script)
  }

  handleScriptLoad() {
    const options = {
      componentRestrictions: { country: 'se' },
      fields: ['address_components', 'formatted_address'],
      strictBounds: false,
      types: ['address']
    }

    const ref = document.getElementById('autocomplete')

    const autoComplete = new window.google.maps.places.Autocomplete(
      ref,
      options
    )

    autoComplete.addListener('place_changed', () => this.handlePlaceSelect())

    this.setState({ autoComplete })
  }

  getLocationComponent(type, components) {
    if (components) {
      for (let i = 0; i < components.length; i++) {
        if (components[i].types.includes(type)) {
          return components[i].long_name
        }
      }
    }

    return null
  }

  async handlePlaceSelect() {
    const { autoComplete } = this.state

    if (autoComplete) {
      const place = autoComplete.getPlace()

      const street = this.getLocationComponent(
        'route',
        place.address_components
      )
      const streetNumber = this.getLocationComponent(
        'street_number',
        place.address_components
      )
      const city = this.getLocationComponent(
        'postal_town',
        place.address_components
      )
      const zipCode = this.getLocationComponent(
        'postal_code',
        place.address_components
      )

      // Used to validate form for valid address.
      this.setState({
        locationError: !street || !streetNumber || !city || !zipCode,
        origin: {
          address: `${street} ${streetNumber}`,
          city,
          zip_code: zipCode
        }
      })

      this.form.current.setFieldsValue({ location: place.formatted_address })
    }
  }

  getTemplates(type) {
    const {
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates
    } = this.props

    switch (type) {
    case Constants.OPERATION_TEMPLATES:
      return Functions.getTemplateOptions(operationTemplates.data, type)
    case Constants.TRANSPORTER_TEMPLATES:
      return Functions.getTemplateOptions(transporterTemplates.data, type)
    case Constants.RECEIVER_TEMPLATES:
      return Functions.getTemplateOptions(receiverTemplates.data, type)
    case Constants.WASTE_TYPE_TEMPLATES:
      return Functions.getTemplateOptions(wasteTypeTemplates.data, type)
    default:
      return []
    }
  }

  setModalValues(values) {
    const { origin, reportType } = this.state
    const {
      auth,
      update,
      operationTemplates,
      transporterTemplates,
      receiverTemplates,
      wasteTypeTemplates,
      report
    } = this.props

    if (update) {
      this.setState({
        confirmationModalOpen: true,
        confirmationModal: {  
          date: report.date.split('T')[0],
          type: report.type,
          report_type: reportType, 
          operation: { ...report.operation },
          origin: {
            address: report.address,
            zip_code: report.zip_code,
            city: report.city,
            municipality_code: report.municipality_code
          },
          receiver: { ...report.receiver },
          transporter: { ...report.transporter },
          waste_types: values.waste.map((item) => {
            // Catches if template is removed...
            let code = item.waste_type

            if (code.length > 10) {
              code = wasteTypeTemplates.data[code].code
            }

            return { id: item.id, code, weight: parseFloat(item.weight) }
          })
        }
      })
    } else {
      const getUnknownOperations = (id, name) => ({
        contact_person: auth.user.first_name + ' ' + auth.user.last_name, 
        email: auth.user.email,
        is_part_of_organization: auth.user.selectedCompany ? auth.user.selectedCompany: auth.user.is_part_of_organization,
        organization_is_swedish: true,
        phone: auth.user.phone,
        cfar_number: id, 
        id: id,
        name: name,
        operation_name: name,
        organization_number: id
      })

      // let operation
      // if (values.operation === '90000') {
      //   operation = getUnknownOperations(values.operation)
      // } else if ( values.operation === '90001') {
      //   operation = getUnknownOperations(values.operation, 'Okänd aktör')
      // } else {
      //   operation = operationTemplates.data[values.operation]
      // }
      const operation = operationTemplates.data[values.operation]
      const transporter = transporterTemplates.data[values.transporter]
      const receiver = receiverTemplates.data[values.receiver]

      console.log('-------', operation)

      this.setState({
        confirmationModalOpen: true,
        confirmationModal: {
          date: reportType === 'transportplanering' ? values.date : new Date().toISOString(),
          type: values.transport_type,
          report_type: reportType,
          operation: { ...operation },
          origin: { ...origin, municipality_code: values.municipality_code },
          receiver: { ...receiver },
          reported_by: auth.user.id,
          transporter: { ...transporter },
          waste_types: values.waste.map((item) => {
            const waste = wasteTypeTemplates.data[item.waste_type]

            return { code: waste.code, weight: parseFloat(item.weight) }
          })
        }
      })
    }
  }

  getInitialValues() {
    const { update, report, wasteTypeTemplates } = this.props

    if (update) {
      return {
        operation: report.operation.operation_name,
        location: report.address,
        municipality_code: report.municipality_code,
        transporter: report.transporter.organization_name,
        receiver: report.receiver.organization_name,
        date: report.date.split('T')[0],
        transport_type: report.type,
        waste: report.waste_types.map((item) => {
          const wasteType = Functions.objectToArray(
            wasteTypeTemplates.data
          ).find((type) => type.code === item.code)

          return {
            waste_type: wasteType ? wasteType.id : item.code,
            weight: item.weight,
            id: item.id
          }
        }),
        receiver_name: '',
        receiver_email: ''
      }
    }

    return {
      operation: null,
      receiver: null,
      municipality_code: null,
      transporter: null,
      waste_type: null,
      location: null,
      transport_type: 'Vägtransport',
      date: '',
      waste: [{ waste_type: null, weight: '' }]
    }
  }

  render() {
    const { confirmationModalOpen, confirmationModal, signImage, signatureReceiverImage, signatureTransporterImage, reportType } = this.state
    const { update, report, reports, auth, history, nvHealth } = this.props
    const operations = [
      ...this.getTemplates(Constants.OPERATION_TEMPLATES)
    ]    
    const transporters = this.getTemplates(Constants.TRANSPORTER_TEMPLATES)
    const receivers = this.getTemplates(Constants.RECEIVER_TEMPLATES)
    const wasteTypes = this.getTemplates(Constants.WASTE_TYPE_TEMPLATES)
    const isCarrier = Functions.getUserRole(auth.user) === 'Transportör'

    return (
      <Row gutter={16} className={'report-waste-form-outer-container'}>
        <Col span={24}>
          {auth.testEnvironment ? (
            <Badge
              header={'Testläge'}
              information={
                'I testläget kan du testa att skapa en rapportering utan att skicka vidare den till Naturvårdsverket. För att faktiskt rapportera in ett farligt avfall, se till att inte vara i testläge.'
              }
              icon={faInfo}
            />
          ) : null}
        </Col>
        <Col span={24}>
          <Row>
            { !update &&
              <Col>
                <h1>{'Skapa anteckning, transportdokument och rapport som transportör eller avfallsproducent'}</h1>
              </Col>

            }
            { update &&
              <Col md={6}>
                <h1>{'Signera och redigera rapporten'}</h1>
              </Col>
            }
            <Col span={18} align="middle">
              { update &&
                <ReportStates report={report}/>
              }
            </Col>
          </Row>

          <Form
            layout={'vertical'}
            initialValues={this.getInitialValues()}
            onFinish={(values) => this.setModalValues(values)}
            ref={this.form}
          >
            { !update &&
              <Section
                header={'Välj avfallsproducent'}
                size={'md'}
                className={'sub-container'}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} xl={12}>
                    <p>{JSON.stringify(update)}</p>
                    <RadioButton
                      disabled={update}
                      checked={this.state.reportType}
                      items={[{value: 'transportplanering', label: 'Verksamhet'}, {value: 'okänd', label: 'Okänd'}, {value: 'hushåll', label: 'Hushåll'}]}
                      onChange={(option) => {
                        this.setState({
                          reportType: option.value
                        })
                        console.log(this.state)
                        // this.props.report.date = new Date().toISOString()
                      }}
                    />
                  </Col>
                </Row>
              </Section>
            }
            {
              reportType !== null && 
                <Section
                  header={reportType === 'transportplanering' ? '' : 'Plats för start av transport'}
                  size={'md'}
                  className={'sub-container'}
                >
                  <Row gutter={[16, 16]}>
                    {
                      reportType === 'transportplanering' &&
                      <Col xs={24} xl={8}>
                        <Form.Item
                          label={isCarrier ? 'Tidigare innehavare' : 'Verksamheten är avfallsproducent'}
                          name={'operation'}
                          rules={
                            update
                              ? []
                              : [{ required: true, message: 'Obligatoriskt fält.' }]
                          }
                        >
                          {update ? (
                            <Input placeholder={'Verksamhet'} disabled />
                          ) : (
                            <Select options={operations} placeholder={'Verksamhet'} />
                          )}
                        </Form.Item>
                      </Col>
                    }
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label={isCarrier ? 'Plats för start av transport' : 'Plats'}
                        name={'location'}
                        rules={
                          update
                            ? []
                            : [
                              { required: true, message: 'Obligatoriskt fält.' },
                              {
                                validator: (_, value) => {
                                  if (!value || !this.state.locationError) {
                                    return Promise.resolve()
                                  }

                                  return Promise.reject(
                                    new Error(
                                      'Adressen är ej giltlig, välj gatunummer.'
                                    )
                                  )
                                }
                              }
                            ]
                        }
                      >
                        <Input
                          placeholder={'Plats'}
                          id={'autocomplete'}
                          disabled={update}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label={'Kommunkod'}
                        name={'municipality_code'}
                        rules={
                          update
                            ? []
                            : [{ required: true, message: 'Obligatoriskt fält.' }]
                        }
                      >
                        {update ? (
                          <Input placeholder={'Kommunkod'} disabled />
                        ) : (
                          <Select
                            options={Object.values(
                              Constants.MUNICIPALITY_CODES
                            ).map((item) => ({
                              value: item.code,
                              label: item.name
                            }))}
                            placeholder={'Kommunkod'}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Section>              
            }
            <Section
              header={'Transportinformation'}
              size={'md'}
              className={'sub-container'}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} xl={4}>
                  <Form.Item
                    label={'Typ'}
                    rules={
                      update
                        ? []
                        : [{ required: reportType === 'transportplanering', message: 'Obligatoriskt fält.' }]
                    }
                    name={'transport_type'}
                  >
                    {update ? (
                      <Input placeholder={'Typ'} disabled />
                    ) : (
                      <Select
                        options={Constants.TRANSPORTATION_TYPES}
                        placeholder={'Typ'}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} xl={6}>
                  <Form.Item
                    label={'Datum'}
                    rules={
                      update
                        ? []
                        : [{ required: reportType === 'transportplanering', message: 'Obligatoriskt fält.' }]
                    }
                    name={'date'}
                  >
                    <Input
                      placeholder={'Datum'}
                      type={'date'}
                      disabled={update}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={7}>
                  <Form.Item
                    label={'Transportör'}
                    rules={
                      update
                        ? []
                        : [{ required: reportType === 'transportplanering', message: 'Obligatoriskt fält.' }]
                    }
                    name={'transporter'}
                  >
                    {update ? (
                      <Input placeholder={'Transportör'} disabled />
                    ) : (
                      <Select
                        options={transporters}
                        placeholder={'Transportör'}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} xl={7}>
                  <Form.Item
                    label={isCarrier ? 'Ny innehavare' : 'Mottagare'}
                    rules={
                      update
                        ? []
                        : [{ required: reportType === 'transportplanering', message: 'Obligatoriskt fält.' }]
                    }
                    name={'receiver'}
                  >
                    {update ? (
                      <Input placeholder={'Mottagare'} disabled />
                    ) : (
                      <Select options={receivers} placeholder={'Mottagare'} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Section>
            {
              reportType !== null &&
              <Section
                header={'Avfallstyp'}
                size={'md'}
                className={'sub-container'}
              >
                <Form.List name={'waste'}>
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map(({ key, name, restField }) => {
                          return (
                            <Row
                              gutter={[16, 16]}
                              key={key}
                              className={'margin-bottom-1rem'}
                            >
                              {update ? (
                                <Col xs={24} xl={8}>
                                  <Form.Item
                                    {...restField}
                                    label={'Avfall ID'}
                                    name={[name, 'id']}
                                  >
                                    <Input placeholder={'Avfalls ID'} disabled />
                                  </Form.Item>
                                </Col>
                              ) : null}
                              <Col xs={24} xl={8}>
                                <Form.Item
                                  {...restField}
                                  label={'Typ'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Obligatoriskt fält.'
                                    }
                                  ]}
                                  name={[name, 'waste_type']}
                                >
                                  <Select
                                    options={wasteTypes}
                                    placeholder={'Typ'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={8}>
                                <Form.Item
                                  {...restField}
                                  label={'Avfallsmängd, kg (kan anges med max 3 decimaler)'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Obligatoriskt fält.'
                                    },
                                    {
                                      pattern: new RegExp(/^[+]?\d+([.]\d+)?$/),
                                      message: 'Positivt tal.'
                                    }
                                  ]}
                                  name={[name, 'weight']}
                                >
                                  <Input type={'number'} placeholder={'Vikt'} />
                                </Form.Item>
                              </Col>
                              {!update && fields.length > 1 ? (
                                <Col xs={24} xl={4}>
                                  <Form.Item {...restField} label={' '}>
                                    <Button filled onClick={() => remove(name)}>
                                      Ta bort
                                    </Button>
                                  </Form.Item>
                                </Col>
                              ) : null}
                            </Row>
                          )
                        })}

                        {update ? null : (
                          <Col xs={24} xl={4}>
                            <Button onClick={() => add()} bordered fitContent>
                              + Lägg till avfallstyp
                            </Button>
                          </Col>
                        )}
                      </div>
                    )
                  }}
                </Form.List>
              </Section>
            }
            { update && <Section
              header={'Signatur avfallslämnare'}
              size={'md'}
              className={'sub-container'}
            >
              <span className="signature-text">{report.reported_by_name}</span>
              {/* <CollapseSignature name={report.reported_by_name}/> */}
            </Section>
            }

            { update && <Section
              header={'Signatur transportör'}
              size={'md'}
              className={'sub-container'}
            > {
                report.signed_at && 
                  <span className="signature-text">{report.transporter_name }</span>
              }
              { auth.user.carrier && !report.signed_at &&
                <Form.Item>
                  <AntdButton onClick={() => this.props.trySignReport(report.id, 'transporter') }><EditOutlined />Signera transport</AntdButton>
                </Form.Item>
              }
              { !report.signed_at && !auth.user.carrier &&
                  <CollapseSignature 
                    name={report.transporter_name} 
                    type={'transporter'}
                    startOpen={true} 
                    reportId={report.id} 
                    trySignReport={this.props.trySignReport}
                    trySendReceipt={this.props.trySendReceipt}
                    signature={signatureTransporterImage}
                    org={Functions.getOmbudOrgId(this.props.auth)}
                    onChange={(img) => {
                      this.setState({signatureTransporterImage: img})
                    }}/>
              }              
            </Section>
            }
            { update && <Section
              header={'Signatur mottagare'}
              size={'md'}
              className={'sub-container'}
            >
              <CollapseSignature 
                name={report.receiver_name} 
                type={'receiver'}
                startOpen={true} 
                reportId={report.id} 
                trySignReport={this.props.trySignReport}
                signature={signatureReceiverImage}
                org={Functions.getOmbudOrgId(this.props.auth)}
                onChange={(img) => {
                  this.setState({signatureReceiverImage: img})
                }}/>

            </Section> }          
            <Row justify={'end'} gutter={[16, 16]}>
              <Col xs={24} xl={8}>
                <Form.Item>
                  {
                    reportType !== null &&
                    <Button
                      htmlType={'submit'}
                      disabled={!nvHealth.up && !auth.testEnvironment}
                    >
                      {update ? 'Uppdatera' : 'Skapa rapport'}
                    </Button>

                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <ControlModal
            data={confirmationModal}
            open={confirmationModalOpen}
            onClose={() => this.setState({ confirmationModalOpen: false })}
            loading={reports.isCreating || reports.isUpdating}
            onFinish={() => {
              if (!auth.testEnvironment) {
                if (update) {
                  this.props.tryUpdateReport(report.id, {
                    data: confirmationModal
                  }, Functions.getOmbudOrgId(this.props.auth))
                } else {
                  this.props.tryCreateReport({ data: confirmationModal }, Functions.getOmbudOrgId(this.props.auth))
                }
              } else {
                this.form.current.resetFields()
                this.setState({ confirmationModalOpen: false })
              }
            }}
            update={update}
            testEnvironment={auth.testEnvironment}
          />
        </Col>
      </Row>
    )
  }
}

function mapStateToProps({
  operationTemplates,
  transporterTemplates,
  receiverTemplates,
  wasteTypeTemplates,
  wasteTypes,
  reports,
  auth,
  nvHealth
}) {
  return {
    operationTemplates,
    transporterTemplates,
    receiverTemplates,
    wasteTypeTemplates,
    wasteTypes,
    reports,
    auth,
    nvHealth
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ReportActions,
      ...AuthActions,
      ...AlertActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportWasteForm))
