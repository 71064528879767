import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL = !process.env.REACT_APP_API
  ? 'http://localhost:3990/v1/organizations-api/'
  : `${process.env.REACT_APP_API}organizations-api/`

export function getOne() {
  return Axios.get(`${BASE_URL}`, Functions.getAuthenticationHeaders())
}

export function updateAPI(data) {
  return Axios.patch(
    `${BASE_URL}/update-token`,
    data,
    Functions.getAuthenticationHeaders()
  )
}

export async function getOmbudOrgs() {
  return await Axios.get(BASE_URL + '/ombuds-organizations', Functions.getAuthenticationHeaders())
}


export async function getOmbud() {
  return await Axios.get(BASE_URL + '/ombud', Functions.getAuthenticationHeaders())
}

export async function updateOmbud(ombudForOrg, role, limited_view_scope) {
  return await Axios.patch(
    `${BASE_URL}/ombud`,
    {
      ombud_for_org: ombudForOrg, 
      role, 
      limited_view_scope
    },
    Functions.getAuthenticationHeaders()
  )
}