import React from 'react'

import { Select as AntdSelect } from 'antd'

// Overrite default antd.
import './styles.css'

const Select = (props) => {
  const {
    onChange,
    placeholder = '',
    options = [],
    defaultValue,
    value,
    error = null
  } = props

  return (
    <>
      <AntdSelect
        showSearch
        placeholder={placeholder}
        optionFilterProp={'children'}
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={defaultValue}
        value={value}
      >
        {options.map((option) => (
          <AntdSelect.Option value={option.value} key={option.value}>
            {option.label}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
      {!value && error ? <p className="error-message">{error}</p> : null}
    </>
  )
}

export default Select
