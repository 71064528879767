import React from 'react'
import { Col } from 'antd'

import './style.css'

import Divider from 'components/common/Divider'

const Section = ({ header, children, actions, subHeader }) => {
  return (
    <Col span={24} className={'section-container'}>
      <div className={'section-inner-container'}>
        <div>
          <h3>{header}</h3>
          {
            subHeader &&
          <span>{subHeader}</span>
          }
        </ div>
        {actions}
      </div>
      <Divider />
      {children}
    </Col>
  )
}
Section.displayName = 'Section'

export default Section
