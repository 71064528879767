import React from 'react'
import { Button as AntdButton } from 'antd'

import Styles from './styles.module.css'

const Button = (props) => {
  const {
    onClick = () => {},
    className,
    htmlType = undefined,
    children,
    style,
    bordered,
    disabled,
    loading,
    fitContent
  } = props

  let containerStyle = Styles['container']

  if (bordered) {
    containerStyle += ` ${Styles['bordered-container']}`
  }

  if (fitContent) {
    containerStyle += ` ${Styles['width-fit']}`
  }

  return (
    <AntdButton
      onClick={() => onClick()}
      className={`${containerStyle} ${className}`}
      htmlType={htmlType}
      style={style}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </AntdButton>
  )
}

export default Button
